import React from "react";
import Slider from "react-slick";

import ProductLayoutTwoOptionTwo from "../../components/layouttwo/ProductLayoutTwoOptionTwo";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import NextArrow from "../../components/elements/carousel/NextArrow";

const BestSellProduct = ({ products, currencyrate, homedetail }) => {
  /*
    To set carouse for product view
  */
  let carouselitem = [];
  if (products && products.length > 0) {
    products.map((item) => {
      item.productname = item.producttitle;
      item.id = item.productid;
      carouselitem.push(
        <div className="carousel-item ps-layout--grid ps-shop-items bn shop-items-list producteffect">
          <div className="ps-category__item m-10">
            <div className="ps-layout__item card-box-shadow bn" key={item.id}>
              <ProductLayoutTwoOptionTwo product={item} currencyrate={currencyrate} />
            </div>
          </div>
        </div>
      );
    });
  }

  /*
    To carousel setting
  */
  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 5,
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {carouselitem && carouselitem.length > 0 && (
        <div className="ps-page ps-page--product home-section-div">
          <div className="container">
            <div className="ps-page__content">
              <div className=" ps-reverse">
                <div className="ps-layout__right">
                  <div className="row pb-20 ph-10 title-row">
                    <div className="col-8">
                      <h3 className="sectionname">{homedetail.displaybestsellsubtitle}</h3>
                      <h3 className="section-main-title">{homedetail.displaybestselltitle}</h3>
                    </div>
                    <div className="col-4 title-action">
                      <a href="/shop">
                        <div className="d-flex">
                          <p className="mr-10">View All</p>
                          <i className="fi fi-rr-arrow-circle-right"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Slider {...carouselSetting} className="ps-carousel home-slider">
                        {carouselitem}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default BestSellProduct;
