import React from "react";
import { Link } from "react-router-dom";

const MyAccountMenu = ({ source, className, menuname }) => {
  /*
    To view 
  */
  let menuView;
  if (source) {
    menuView = source.map((item) => {
      return (
        <li key={item.text} className={item.text === menuname.menuname ? "active nav-item" : "nav-item title-font"}>
          <Link to={item.url} className={item.text === menuname.menuname ? "nav-link active" : "nav-link title-font"}>
            <span className="pr-15">{item.icon ? <i className={item.icon}></i> : ""}</span>
            <span className="title-font">{item.text}</span>
          </Link>
        </li>
      );
    });
  } else {
    menuView = (
      <li>
        <a href="#" onClick={(e) => e.preventDefault()}>
          No menu item.
        </a>
      </li>
    );
  }
  return <ul className={className}>{menuView}</ul>;
};

export default MyAccountMenu;
