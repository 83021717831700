import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import useEcomerce from "../../../../hooks/useEcomerce";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { toggleDrawer } from "../../../../store/app/action";

const ModuleHeaderActions = ({ ecomerce, username }) => {
  const [cartTotal, setCartTotal] = useState(0);
  const [wishlistTotal, setWishlistTotal] = useState(0);
  const { wishlistandcartCount, getWishlistAndCartCount } = useEcomerce();
  const { blankLocalStorage } = useLocalStorage();

  let totalcart = 0;
  let totalwishlist = 0;

  useEffect(() => {
    addClass();
    if (username) {
      getWishlistAndCartCount();
    }
  }, [ecomerce]);

  /*
    To set cart count and wishlist count in variable  
  */
  if (username) {
    totalcart = wishlistandcartCount.totalcartcount;
    totalwishlist = wishlistandcartCount.totalwishlistcount;
  }

  /*
    To class add and remove
  */
  const addClass = () => {
    const headerElement = document.getElementById("header_actions");
    if (headerElement) {
      if (username) {
        headerElement.classList.add("ml-0");
      } else {
        headerElement.classList.remove("ml-0");
      }
    }
  };

  const dispatch = useDispatch();

  /*
    To open model and remove overflow-auto class from body
  */
  function handleOpenDrawer(e) {
    dispatch(toggleDrawer(true));
    document.body.classList.remove("overflow-auto");
  }

  /*
    To logout and redirect to home page
  */
  const logout = () => {
    blankLocalStorage();
    setTimeout(() => {
      window.location.href = "/";
    }, 500);
  };

  /*
    To close model and redirect to login page
  */
  function handleClose(e) {
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  }

  return (
    <ul className="header__actions" id="header_actions">
      {username && username !== null && username !== "" ? (
        <React.Fragment>
          <li>
            <a href="/profile">
              <span className="header__action">
                <span className="ps-text--contact-number login-username ">{username}</span>
              </span>
            </a>
          </li>
          <li>
            <a onClick={logout} title="Logout">
              <span className="header__action">
                <i className="fa fa-sign-out "></i>
              </span>
            </a>
          </li>
        </React.Fragment>
      ) : (
        <li>
          <a onClick={(e) => handleClose(e)} title="Login">
            <span className="header__action">
              <i className="icon-user"></i>
            </span>
          </a>
        </li>
      )}

      <li>
        <a className="header__action" href="/wishlist">
          <i className="fa fa-heart-o"></i>
          <span className="header__action-badge">{wishlistTotal ? wishlistTotal : totalwishlist}</span>
        </a>
      </li>
      <li>
        <a className="header__action" id="cart-mini" onClick={(e) => handleOpenDrawer(e)}>
          <i className="icon-cart-empty"></i>
          <span className="header__action-badge">{cartTotal ? cartTotal : totalcart}</span>
        </a>
      </li>
    </ul>
  );
};
export default connect((state) => state)(ModuleHeaderActions);
