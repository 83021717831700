import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import ModuleEcomerceCartItems from "../../components/ecomerce/modules/ModuleEcomerceCartItems";
import ModuleEcomerceCartSummary from "../../components/ecomerce/modules/ModuleEcomerceCartSummary";
import BreadCrumb from "../../components/elements/BreadCrumb";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import Container from "../../components/layouts/Container";
import FormCheckout from "../../components/shared/forms/FormCheckout";
import useEcomerce from "../../hooks/useEcomerce";
import LocalstorageParam from "../common/LocalstorageParam";
import CartParameter from "./CartParameter";
import BillingAddress from "./BillingAddress";

const Checkout = ({ ecomerce }) => {
  const ref = useRef(null);

  const { cart_list, getCartlist, setCartlist } = useEcomerce();
  const { currencyrate, getcurrencyRate } = useEcomerce();
  const { countrylist, getCountryList } = useEcomerce();

  const [pecialPrice, setSpecialPrice] = useState(0);
  const [sameshipping, setSameshipping] = useState(localStorage.getItem("sameshipping"));
  const [isdefaultaddress, sethandelisdefault] = useState();
  const [applyCoupon, setapplyCoupon] = useState("");
  const [proceedFlag, setProceedFlag] = useState(true);
  const [billingInfo, setBillingInfo] = useState();
  const [billingDetails, setBillingDetails] = useState();
  const [defaultaddressValue, setDefaultaddressValue] = useState();
  const [initialAddress, setInitialAddress] = useState();
  const [isChecked, setIschecked] = useState(true);

  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();

  let cartdata = cart_list;

  /*
    To bread crumb json
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shop",
      url: "/shop",
    },
    {
      text: "Shopping cart",
      url: "/cart?coupon=" + searchparam.urlcoupon + "&rewardpoints=" + searchparam.urlpoints,
    },
    {
      text: "Checkout",
    },
  ];

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  const shippingValue = localStorage.getItem("sameshipping");

  useEffect(() => {
    getcurrencyRate();
    getCountryList();
    const handleShippingValue = (value) => {
      setSameshipping(value ? "1" : "0");
      localStorage.setItem("sameshipping", value ? "1" : "0");
      setIschecked(value);
      document.getElementById("sameshippingaddress").checked = value;
    };

    if (shippingValue === null || shippingValue === "1") {
      handleShippingValue(true);
    } else {
      handleShippingValue(false);
    }

    if (localparam && localparam.decoded?.username) {
      if (ecomerce.cartItems) {
        getCartlist(ecomerce.cartItems, "cart");
      }
    }

    const storedBillingData = localStorage.getItem("billingdata");
    if (storedBillingData) {
      const billingData = JSON.parse(storedBillingData);
      setDefaultaddressValue(billingData);
      setBillingInfo(billingData);
      setBillingDetails(billingData);
    } else {
      setBillingInfo(billingInfo);
      setBillingDetails(billingInfo);
    }
  }, [ecomerce.cartItems, searchparam.urlcoupon, shippingValue, isdefaultaddress]);

  useEffect(() => {
    setProceedFlag(true);
    let count = 0;
    if (cart_list) {
      cart_list.forEach((item) => {
        if (Number(item.stock) < Number(item.quantity)) {
          count++;
        }
      });
    }
    if (count > 0) {
      setProceedFlag(false);
    }
  }, [cart_list]);

  useEffect(() => {
    const setInitialDefaultAddress = () => {
      const addressValues = localStorage.getItem("addressvalues");
      if (addressValues) {
        try {
          const add = JSON.parse(addressValues);
          const billingDetails = {
            billingname: add.fullname,
            billingcountry: add.country,
            billingstate: add.state,
            billingcity: add.city,
            billingpincode: add.pincode,
            billingaddress: add.address,
            billingmobilecountry: add.addressphonecountry,
            billingmobile: add.addressphonenumber,
            billingcompanyname: add.companyname,
            billingcountryname: add.countryname,
            billingstatename: add.statename,
          };
          setDefaultaddressValue(billingDetails);
        } catch (error) {
          console.error("Failed to parse address values:", error);
        }
      }
    };

    setInitialDefaultAddress();
  }, []);

  const compareBillingDetails = (initialAddress, formDataObject) => {
    var keys1 = Object.keys(initialAddress);
    var keys2 = Object.keys(formDataObject);

    let mismatchFound = false;

    for (var key of keys1) {
      if (!(key in formDataObject)) {
        continue;
      }
      if (initialAddress[key] !== formDataObject[key]) {
        mismatchFound = true;
        break;
      }
    }
    return !mismatchFound;
  };

  /*
    To set shipping and billing adddress as same
  */
  const sameShippingAndBillingAddress = (val) => {
    setSameshipping(val ? "1" : "0");
    localStorage.setItem("sameshipping", val ? "1" : "0");
    document.getElementById("sameshippingaddress").checked = val;

    if (val) {
      const formData = new FormData(document.getElementById("billingForm"));
      const formDataObject = Object.fromEntries(formData.entries());

      const billingDetailsMatch = compareBillingDetails(initialAddress, formDataObject);
      if (!billingDetailsMatch) {
        const form = document.getElementById("billingForm");
        form.reset();

        setBillingDetails(initialAddress);
        setBillingInfo(initialAddress);
        localStorage.setItem("billingdata", JSON.stringify(initialAddress));
      }
    } else {
      const storedBillingData = localStorage.getItem("billingdata");
      if (storedBillingData) {
        const billingData = JSON.parse(storedBillingData);
        setBillingInfo(billingData);
        setBillingDetails(billingData);
      }
    }
  };

  /*
    To note set in local storage
  */
  const setNote = (value) => {
    localStorage.removeItem("note");
    if (value) {
      localStorage.setItem("note", value);
    }
  };

  const setAddressValues = (addressValues) => {
    if (addressValues) {
      try {
        const add = JSON.parse(addressValues);

        const billingDetails = {
          billingname: add.fullname,
          billingcountry: add.country,
          billingstate: add.state,
          billingcity: add.city,
          billingpincode: add.pincode,
          billingaddress: add.address,
          billingmobilecountry: add.addressphonecountry,
          billingmobile: add.addressphonenumber,
          billingcompanyname: add.companyname,
          billingcountryname: add.countryname,
          billingstatename: add.statename,
          billinggst: "",
        };
        setInitialAddress(billingDetails);

        if (document.getElementById("sameshippingaddress").checked || localStorage.getItem("sameshipping") === "1") {
          if (billingInfo && billingInfo.billinggst) {
            billingDetails.billinggst = billingInfo.billinggst;
          }
          localStorage.setItem("billingdata", JSON.stringify(billingDetails));
          setBillingInfo(billingDetails);
        }
      } catch (error) {
        console.error("Failed to parse address values:", error);
      }
    }
  };

  return (
    <Container title="Checkout">
      <GoogleCaptcha />
      <div className="ps-page ps-page--shopping form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">Checkout</h1>
          </div>
          <div className="ps-page__content">
            <div className="ps-cart__items card-box-shadow bn p-20 mb-20 hide">
              <ModuleEcomerceCartItems
                cartItems={cart_list}
                cartevent="shoppingcart"
                setSpecialPrice={setSpecialPrice}
                currencyrate={currencyrate}
              />
            </div>
            <div className="ps-checkout">
              <div className="ps-checkout__wapper"></div>
              <div className="row">
                <div className="col-12 col-md-8">
                  <FormCheckout
                    sethandelisdefault={(value) => sethandelisdefault(value)}
                    setAddressValues={(address) => setAddressValues(address)}
                    sameasshipping={localStorage.getItem("sameshipping")}
                  />
                  <div className="form-check ml-0 mb-30">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sameshippingaddress"
                      onChange={(e) => sameShippingAndBillingAddress(e.target.checked)}
                    />
                    <label className="form-check-label mt-0" htmlFor="sameshippingaddress">
                      Billing address is same as shipping address
                    </label>
                  </div>
                  <div className="ps-form--checkout card-box-shadow bn p-30 mb-30">
                    <div className="ps-form__billings ps-form--review">
                      <h2 className="ps-form__title">Billing Address</h2>
                      <div className="ps-form--review ">
                        <BillingAddress
                          ref={ref}
                          countrylist={countrylist}
                          searchparam={searchparam}
                          localparam={billingInfo}
                          sameasshipping={localStorage.getItem("sameshipping")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ps-form--review card-box-shadow p-30 mb-30">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label className="ps-form__label">Customer Note </label>
                          <textarea
                            className="form-control ps-form__textarea"
                            placeholder="Note"
                            maxLength="500"
                            onChange={(e) => {
                              setNote(e.target.value);
                            }}
                          >
                            {localStorage.getItem("note")}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <ModuleEcomerceCartSummary
                    cartItems={cartdata}
                    cartevent={"summery"}
                    sameshippingdata={sameshipping}
                    setSpecialPrice={setSpecialPrice}
                    currencyrate={currencyrate}
                    isdefaultaddress={isdefaultaddress}
                    onSetCartlist={setCartlist}
                    setapplyCoupon={setapplyCoupon}
                    proceedFlag={proceedFlag}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default connect((state) => state)(Checkout);
