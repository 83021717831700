import { useState } from "react";

import sitemenu from "../api/menu";
import ProductRepository from "../api/ProductRepository";
import { getProductsByCollectionHelper } from "../utilities/strapi-fetch-data-helpers";
import CollectionRepository from "../repositories/CollectionRepository";

export default function useGetProducts() {
  const [loading, setLoading] = useState(false);
  const [productItems, setProductItems] = useState(null);
  const [productItemsByCategory, setProductItemsByCategory] = useState(null);
  const [productItemsByCollection, setProductItemsByCollection] = useState(null);
  const [product, setProduct] = useState(null);
  const [comboproduct, setComboProduct] = useState(null);
  const [likeproduct, setLikeProduct] = useState(null);
  const [category, setCategory] = useState(null);
  const [productReview, setProductReview] = useState(null);
  const [productMaxMin, setproductMaxMin] = useState(null);
  const [productvariant, setProductvariant] = useState(null);
  const [filtergroup, setFilterGroup] = useState(null);
  const [categorymenu, setCategoryMenu] = useState(null);
  const [mainmenu, setMainMenu] = useState(null);
  const [productviewcount, setProductViewCount] = useState(null);
  const [productfaq, setProductFaq] = useState(null);
  return {
    loading,
    product,
    comboproduct,
    productItems,
    productItemsByCategory,
    productItemsByCollection,
    category,
    productReview,
    productMaxMin,
    productvariant,
    filtergroup,
    categorymenu,
    likeproduct,
    mainmenu,
    productviewcount,
    productfaq,
    setProduct,
    setComboProduct,
    setLoading: (payload) => {
      setLoading(payload);
    },

    getProductsByCollection: async (payload, pageSize = 8) => {
      setLoading(true);
      const responseData = await getProductsByCollectionHelper(payload, pageSize);
      if (responseData) {
        setProductItems(responseData.items);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },
    /*
      To get product list
    */
    getProducts: async (payload) => {
      setLoading(true);
      let responseData;
      if (payload.pageno) {
        if (payload) {
          responseData = await ProductRepository.getProducts(payload);
        } else {
          const queries = {
            _limit: 12,
          };
          responseData = await ProductRepository.getProducts(queries);
        }
      }

      if (responseData) {
        setProductItems(responseData);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },
    /*
      To get product data by id
    */
    getProductById: async (payload) => {
      setLoading(true);
      const responseData = await ProductRepository.getProductsById(payload);

      if (responseData) {
        if (responseData.payload) {
          if (responseData.payload.productdetail.data) {
            setProduct(responseData.payload.productdetail.data[0]);
          }
          if (responseData.payload.comboproducts) {
            setComboProduct(responseData.payload.comboproducts);
          }
          if (responseData.payload.similarproduct) {
            setLikeProduct(responseData.payload.similarproduct.data);
          }
          if (responseData.payload.productreviewcount) {
            setProductViewCount(responseData.payload.productreviewcount);
          }
          if (responseData.payload.productfaqlist) {
            setProductFaq(responseData.payload.productfaqlist);
          }

          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        } else {
          setLoading(false);
          setProduct("");
        }
      }
    },
    /*
      To get category list
    */
    getCategory: async (parentcategory) => {
      setLoading(true);
      const response = await ProductRepository.getProductCategories(parentcategory);
      if (response) {
        setCategory(response.data);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },
    /*
      To get product list by category id
    */
    getProductByCategoryId: async (categorySlug, pageno, filters) => {
      setLoading(true);

      let responseData;
      if (pageno) {
        responseData = await ProductRepository.getProductByCategoryId(categorySlug, pageno, filters);
      }

      if (responseData) {
        if (responseData.productslist) {
          setProductItemsByCategory(responseData.productslist);
        } else {
          setProductItemsByCategory([]);
        }
      } else {
        setProductItemsByCategory([]);
      }

      setLoading(false);
    },
    /*
      To get product list by collection id
    */
    getProductByCollectionId: async (categorySlug, pageno, filters) => {
      setLoading(true);

      const responseData = await CollectionRepository.getProductByCollectionId(categorySlug, pageno, filters);

      if (responseData) {
        if (responseData.productslist) {
          setProductItemsByCollection(responseData.productslist);
        } else {
          setProductItemsByCollection([]);
        }
      } else {
        setProductItemsByCollection([]);
      }

      setLoading(false);
    },
    /*
      To get product review list by product id
    */
    getProductReviewById: async (uniqueid) => {
      setLoading(true);
      const responseData = await ProductRepository.getProductReviewById(uniqueid);
      if (responseData) {
        setProductReview(responseData);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },
    /*
      To get product minmum and maximum price
    */
    getProductMaxMin: async () => {
      setLoading(true);
      const responseData = await ProductRepository.getProductMaxMin();
      if (responseData) {
        setproductMaxMin(responseData);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },
    /*
      To get product varient list by product id
    */
    getProductVariant: async (payload) => {
      setLoading(true);
      const responseData = await ProductRepository.getProductVariant(payload);
      if (responseData) {
        setProductvariant(responseData);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },
    /*
      To get filter group list
    */
    getFilterGroup: async () => {
      setLoading(true);
      const responseData = await ProductRepository.getFilterGroup();
      if (responseData) {
        setFilterGroup(responseData.data);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },
    /*
      To get category menu list
    */
    getCategoryMenu: async () => {
      setLoading(true);
      const responseData = await ProductRepository.getCategoryMenu();
      if (responseData) {
        setCategoryMenu(responseData.data);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },
    /*
      To get sitemain manu list
    */
    getSiteMainMenu: async () => {
      setLoading(true);
      const responseData = await sitemenu.getSiteMainMenu();
      if (responseData) {
        setMainMenu(responseData.data.payload.data);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      } else {
        setMainMenu(null);
      }
    },
  };
}
