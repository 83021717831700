import React from "react";
import Slider from "react-slick";

import ProductLayoutTwoOptionTwo from "../../../../layouttwo/ProductLayoutTwoOptionTwo";

const ModuleSuggestedProduct = ({ comboproduct, currencyrate }) => {
  /*
    To display suggested products
  */
  let carouselitem = [];
  if (comboproduct && comboproduct.length > 0) {
    comboproduct.map((item, index) => {
      comboproduct.productid = item.id;
      carouselitem.push(
        <div className="carousel-item ps-layout--grid ps-shop-items bn shop-items-list producteffect">
          <div className="ps-category__item m-10">
            <div className="ps-layout__item card-box-shadow bn" key={index}>
              <ProductLayoutTwoOptionTwo product={item} currencyrate={currencyrate} />
            </div>
          </div>
        </div>
      );
    });
  }

  /*
    To carousel setting
  */
  const suggestedProductsSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1176,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {carouselitem && carouselitem.length > 0 && (
        <div className="ps-page ps-page--product home-section-div suggested-product-container">
          <div className="ps-page__content">
            <div className=" ps-reverse">
              <div className="ps-layout__right">
                <div className="row title-row">
                  <div className="col-12">
                    <h3 className="mt-20">Suggested Products</h3>
                  </div>
                </div>
                <div className="row similar-slider-container">
                  <div className="col-12 p-0">
                    <Slider {...suggestedProductsSettings} className="ps-carousel home-slider">
                      {carouselitem}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModuleSuggestedProduct;
