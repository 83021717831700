import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Drawer } from "antd";

import Logo from "../../elements/basic/Logo";
import FormSearchHeader from "../forms/FormSearchHeader";
import HeaderMenuDrawer from "../drawers/HeaderMenuDrawer";
import useHeader from "../../../hooks/useHeader";
import useGetProducts from "../../../hooks/useGetProducts";

const HeaderMobileOne = ({ note, ecomerce }) => {
  const { isMenu, fullname, totalcart, totalwishlist, handleCloseMenu, handleOpenMenu } = useHeader();
  const { mainmenu, getSiteMainMenu } = useGetProducts();
  const location = useLocation();
  const [search, setSearch] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  /*
		To handle toggle search
	*/
  const handleToggleSearch = (e) => {
    e.preventDefault();
    setSearch(!search);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getSiteMainMenu();
  }, [ecomerce]);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="header-mobile--one">
        {location.pathname === "/" && note?.homedetail && note.homedetail.topbartitle && (
          <div className="announcements">
            <div className="scrolling-text">
              <div className="announcement">{note.homedetail.topbartitle}</div>
              <div className="announcement">{note.homedetail.topbartitle}</div>
              <div className="announcement">{note.homedetail.topbartitle}</div>
              <div className="announcement">{note.homedetail.topbartitle}</div>
              <div className="announcement">{note.homedetail.topbartitle}</div>
              <div className="announcement">{note.homedetail.topbartitle}</div>
            </div>
          </div>
        )}
        <header className={`header-mobile-one ${isScrolled ? "header-mobile-one-scrolled" : ""}`}>
          <div className="header__menu" onClick={handleOpenMenu}>
            <i class="fi fi-rr-apps"></i>
          </div>
          <div className="header_logo">
            <Logo height={60} />
          </div>
          <div className="header__search" onClick={(e) => handleToggleSearch(e)}>
            <i class="fi fi-rr-search"></i>
          </div>
        </header>
        {search && (
          <div className="header-one__searchbox">
            <FormSearchHeader />
          </div>
        )}
      </div>
      <Drawer
        className="ps-panel--mobile  mobile-home-one"
        closable={false}
        placement="left"
        onClose={(e) => handleCloseMenu(e)}
        visible={isMenu}
      >
        <HeaderMenuDrawer
          fullname={fullname}
          totalcart={totalcart}
          totalwishlist={totalwishlist}
          handleCloseMenu={(e) => handleCloseMenu(e)}
          mainmenu={mainmenu}
        />
      </Drawer>
    </>
  );
};

export default connect((state) => state)(HeaderMobileOne);
