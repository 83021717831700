import React from "react";
import { Link } from "react-router-dom";

import config from "../../../config/config.json";

const ModuleBlog = ({ bloglist, isDetailPage = false }) => {
  let imageBaseURL = config.apiUrl + config.image_path;

  function getImageURL(imagename) {
    return imagename ? `${imageBaseURL}/${imagename}` : "/static/img/banners/banner.png";
  }
  function isFallbackImage(imagename) {
    return !imagename;
  }

  return (
    <>
      {bloglist &&
        bloglist.length > 0 &&
        bloglist.map((item) => (
          <div className="col mb-30 card-blog">
            <div className="card h-100 card-box-shadow">
              <Link to={`/blogs/${item.url}`} className="hover-card">
                <div className="card-header bg-transparent">
                  <h4 className="card-title mt-20 mb-10">{item.title}</h4>
                  <small className="text-muted">
                    {item.blogdisplaycatname} Category | Publish on {item.publishedon}
                  </small>
                </div>
                <div className="card-body">
                  <div className="blog-image">
                    <img
                      src={getImageURL(item.imagename)}
                      className={`blog_image ${
                        isFallbackImage(item.imagename) ? "object-fit-cover" : "object-fit-contain"
                      }`}
                      alt="blogImage"
                    />
                  </div>
                  <hr />
                  <div className="blog-summary mt-5 p-10">
                    <p className="">{item.summary}</p>
                  </div>
                </div>
                <div className="card-bottom p-10 mb-20">
                  <div className="share-option">
                    <span className="p-5 ml-1 ico-container">
                      <i className="fi fi-rr-comment-alt" />
                      <span className="mr-4 blog-text-count">{item.totalcomment}</span>
                    </span>

                    <span className="p-5 ico-container">
                      <i className="fi fi-rr-overview" />
                      <span className="mr-4 blog-text-count">{item.totalview}</span>
                    </span>

                    {item.tagslist && item.tagslist !== null && item.tagslist !== "" ? (
                      item.tagslist
                        .slice(0, isDetailPage ? item.tagslist.length : 3)
                        .map((tag) => <span className="badge card-bottom-badge mr-1">{tag.tagname}</span>)
                    ) : (
                      <>
                        {item.tagslist.map((tag) => (
                          <span className="badge card-bottom-badge mr-1">{tag.tagname}</span>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
    </>
  );
};

export default ModuleBlog;
