import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Error404 from "../../components/common/Error404";
import BreadCrumb from "../../components/elements/BreadCrumb";
import SkeletonTable from "../../components/elements/skeletons/SkeletonTable";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import config from "../../config/config.json";
import { currencyOrdePrice } from "../../hooks/currencyCalculation";
import useEcomerce from "../../hooks/useEcomerce";
import useProduct from "../../hooks/useProduct";
import LocalstorageParam from "../common/LocalstorageParam";
import OderDetailProductList from "./OderDetailProductList";
import OrderHistoryStatus from "./OrderHistoryStatus";
import OrderSummary from "./OrderSummary";
import ShippingBillingAddress from "./ShippingBillingAddress";

const OrderDetail = () => {
  const { loading, returnproductlist, orderdetail, getOrderDetail } = useEcomerce();
  const { ordershippment, getOrderShippment } = useEcomerce();
  const [disable, handelDisable] = useState(false);

  const { thumbnailImages } = useProduct();
  const navigate = useNavigate();
  const { id } = useParams();

  var order_days_count = 0;

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To set breadcrumb
  */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "My Account",
      url: "/profile",
    },
    {
      id: 3,
      text: "Order",
      url: "/order",
    },
    {
      id: 3,
      text: "Order Detail",
    },
  ];

  useEffect(() => {
    handelDisable(true);
    setTimeout(() => {
      handelDisable(false);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.username !== null &&
        localparam.decoded.username !== ""
      ) {
        getOrderDetail(id);
        getOrderShippment(id);
        window.scrollTo(0, 0);
      } else {
        navigate("/login");
      }
    }, 500);
  }, []);

  let producttrackflag = true;
  let trackbtn;

  /*
    To track shippment button
  */
  if (orderdetail && orderdetail.length > 0) {
    if (orderdetail[0].status === "Partially Shipped" || orderdetail[0].status === "Shipped") {
      for (var shippment of ordershippment) {
        if (orderdetail[0].orderproductlist.length === shippment.shippingproductslist.length) {
          producttrackflag = false;
          trackbtn = (
            <a
              href={shippment.trackingurl && shippment.trackingid ? shippment.trackingurl + shippment.trackingid : ""}
              target="_blank"
              title="Track Shippment"
              className={"ps-btn ps-btn--warning "}
            >
              Track Shippment
            </a>
          );
        }
      }
    }
  }

  /*
    To set currency in variable
  */
  let currencyrate = "";
  if (orderdetail && orderdetail.length > 0) {
    currencyrate = orderdetail[0].currencyForm;
  }

  /*
    To set return data set in array
  */
  let orderreturndata = [];
  if (returnproductlist && returnproductlist !== null && returnproductlist !== "" && returnproductlist[0].productlist) {
    returnproductlist[0].productlist.map((item) => {
      let productvariantjson = "";
      if (item.variantjson && item.variantjson !== null && item.variantjson !== "") {
        productvariantjson = JSON.parse(item.variantjson);
      }

      orderreturndata.push(
        <tr>
          <td className="text-left">
            <div className="ps-product--on-cart simple">
              <div className="ps-product__thumbnail list-image pb-5">
                <div className="ps-product__images">{thumbnailImages(item)}</div>
              </div>
              <div className="align-self-center">
                <h4 className="ps-product__title">
                  <a href={`/product/${item.uniqueid}`}>{item.varianttitle ? item.varianttitle : item.producttitle}</a>
                </h4>
                <p className="font-size-11">
                  {productvariantjson ? (
                    <span>{item.varianthsn && `HSN: ${item.varianthsn}`}</span>
                  ) : (
                    <span>{item.hsn && `HSN: ${item.hsn}`}</span>
                  )}
                </p>
                <span>
                  {productvariantjson && (
                    <>
                      {Object.entries(productvariantjson).map(([key, value]) => (
                        <p className="font-size-11">{key} : {value}</p>
                      ))}
                    </>
                  )}
                </span>
              </div>
            </div>
          </td>
          <td className="vertical-align-middle color-blue text-center">
            <div className="ps-product--on-cart simple">
              <div className="mobile-show"></div>
              <div>
                <p className="ps-product__price price-font">
                  {item.offerprice && Number(item.offerprice) > 0 ? (
                    <div className="row">
                      <div className="col-12">
                        <span>{currencyOrdePrice(Number(item.offerprice), currencyrate)}</span>
                        <del className="ml-10">
                          <span>{currencyOrdePrice(Number(item.price), currencyrate)}</span>
                        </del>
                        <span> x</span>
                        <span>{item.returnedquantity}</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {currencyOrdePrice(Number(item.price), currencyrate)}
                      {"  "} x<span>{item.returnedquantity}</span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </td>
          <td className="title-font text-center pr-20">
            {item.offerprice && Number(item.offerprice) > 0 ? (
              <p className={"ps-product__price price-font"}>
                {currencyOrdePrice(Number(item.offerprice) * Number(item.returnedquantity), currencyrate)}
              </p>
            ) : (
              <>
                <p className={"ps-product__price price-font"}>
                  {currencyOrdePrice(Number(item.price) * Number(item.returnedquantity), currencyrate)}
                </p>
              </>
            )}
          </td>
        </tr>
      );
    });
  }

  /*
    To order product list views
  */
  let orderitemlist = "";
  let errorcomp = "";

  if (loading) {
    orderitemlist = <SkeletonTable rows={2} />;
  }
  if (orderdetail && orderdetail.length > 0) {
    orderitemlist = (
      <table className="table ps-table ps-table--shopping-cart ps-table--responsive bn" id="orderitem">
        <tbody className="bn">
          <OderDetailProductList
            orderItem={orderdetail[0].orderproductlist}
            ordershippment={ordershippment}
            producttrackflag={producttrackflag}
            orderdetail={orderdetail}
          />
        </tbody>
      </table>
    );

    if (orderdetail[0].orderhistorylist !== null && orderdetail[0].orderhistorylist !== "") {
      orderdetail[0].orderhistorylist.map((item) => {
        if ("ORDER_COMPLETED" === item.orderevent) {
          var date = item.entrydate.split("@")[0];
          var day = date.split("-")[0];
          var month = date.split("-")[1];
          var year = date.split("-")[2];
          var order_date = moment(new Date(month + "/" + day + "/" + year));
          var today = moment(new Date());
          var days = today.diff(order_date, "days");
          if (Number(days) > 0) {
            order_days_count = days + 1;
          }
        }
      });
    }
  }
  if (!orderdetail) {
    errorcomp = <Error404 />;
  }

  /*
    To download invoice using invoice pdf name
  */
  const downloadInvoice = async (invoicepdfname) => {
    var filename = config.apiUrl + config.pdf_path + invoicepdfname;
    fetch(filename).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = invoicepdfname;
        alink.click();
      });
    });
  };

  /*
    To redirect to order return page 
  */
  const openReturnOrderModel = () => {
    navigate("/orderreturn/" + id);
  };

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              {orderitemlist ? (
                <div className="ps-layout--with-sidebar myaccount">
                  <div className="ps-layout__left">
                    <NavigationLeft menuname={"Orders"} />
                  </div>
                  <div className="ps-layout__right">
                    <div className="ps-form--review">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <h2 className="ps-form__title">
                            Order Number #{" "}
                            {orderdetail && orderdetail.length > 0 && orderdetail[0].orderno
                              ? orderdetail[0].orderno
                              : "-"}
                          </h2>
                        </div>
                        <div className="col-12 col-md-6 detail-btn pb-20">
                          {orderdetail &&
                            orderdetail.length > 0 &&
                            orderdetail[0].status === "Completed" &&
                            Number(order_days_count) <= Number(config.track_display_day) ? (
                            <>
                              {/* <a
                                title="Order Return"
                                className={"ps-btn ps-btn--warning "}
                                onClick={openReturnOrderModel}
                              >
                                Order Return
                              </a> */}
                            </>
                          ) : (
                            <>{trackbtn}</>
                          )}

                          <a href="\order" title="Back" className={"warningbtn ml-10"}>
                            Back
                          </a>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="box-rounded width-100">
                            <div className="ps-cart__content">
                              <div className="ps-cart__items pt-20 pb-10">{orderitemlist && orderitemlist}</div>
                            </div>
                          </div>
                          <div className="col-12 pt-20 box-rounded width-100 mt-20 pb-10">
                            <div className="ps-cart__content">
                              <div className="ps-cart__items order-summary bn">
                                <div className="row">
                                  <div className="col-7 col-md-6 mb-10">
                                    <h2 className="ps-form__title">Payment Detail</h2>
                                  </div>
                                  {orderdetail &&
                                    orderdetail.length > 0 &&
                                    orderdetail[0].status === "Completed" &&
                                    orderdetail[0].invoicepdfname !== null && (
                                      <div className="col-5 col-md-6 invoicedata">
                                        <a
                                          title="Download Invoice"
                                          onClick={(e) => {
                                            downloadInvoice(orderdetail[0].invoicepdfname);
                                          }}
                                        >
                                          <img src={"/static/img/icon/download.png"} className="icon-image pr-10"></img>
                                          Download Invoice
                                        </a>
                                      </div>
                                    )}
                                </div>
                                <div className="row">
                                  <div className="col-6 order-pading">
                                    <span className="ps-text--order">Payment Status</span>
                                  </div>
                                  <div className="col-6 text-right order-pading">
                                    <span className="ps-text--order title-font">
                                      {orderdetail &&
                                        orderdetail.length > 0 &&
                                        orderdetail[0].paymentstatus &&
                                        orderdetail[0].paymentstatus}
                                    </span>
                                  </div>
                                </div>
                                {orderdetail && orderdetail.length > 0 && orderdetail[0].paymentmode && (
                                  <div className="row">
                                    <div className="col-6 order-pading">
                                      <span className="ps-text--order">Payment Mode</span>
                                    </div>
                                    <div className="col-6 text-right order-pading">
                                      <span className="ps-text--order title-font">{orderdetail[0].paymentmode}</span>
                                    </div>
                                  </div>
                                )}
                                {orderdetail && orderdetail.length > 0 && orderdetail[0].transactionid && (
                                  <div className="row">
                                    <div className="col-6 order-pading">
                                      <span className="ps-text--order">Transaction Id</span>
                                    </div>
                                    <div className="col-6 text-right order-pading word-break-all">
                                      <span className="ps-text--order title-font">{orderdetail[0].transactionid}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {returnproductlist &&
                            returnproductlist !== null &&
                            returnproductlist !== "" &&
                            returnproductlist[0].productlist && (
                              <div className="col-12 pt-20 box-rounded width-100 mt-20 pb-10">
                                <div className="ps-cart__content">
                                  <div className="">
                                    <div className="row">
                                      <div className="col-7 col-md-6 mb-10">
                                        <h2 className="ps-form__title">Return Detail</h2>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <table className="table ps-table ps-table--shopping-cart ps-table--responsive bn">
                                        <tbody className="bn">{orderreturndata}</tbody>
                                      </table>
                                    </div>
                                    <div className="row">
                                      <div className="col-6 order-pading">
                                        <span className="ps-text--order">Status</span>
                                      </div>
                                      <div className="col-6 text-right order-pading">
                                        <span className="ps-text--order title-font">{returnproductlist[0].status}</span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-6 order-pading">
                                        <span className="ps-text--order">Return Date</span>
                                      </div>
                                      <div className="col-6 text-right order-pading">
                                        <span className="ps-text--order title-font">
                                          {returnproductlist[0].returndate}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-6 order-pading">
                                        <span className="ps-text--order">Refund Amount</span>
                                      </div>
                                      <div className="col-6 text-right order-pading">
                                        <span className="ps-text--order title-font">
                                          {currencyOrdePrice(Number(returnproductlist[0].refundamount), currencyrate)}
                                        </span>
                                      </div>
                                    </div>
                                    {returnproductlist[0].refundtransactionid && (
                                      <div className="row">
                                        <div className="col-6 order-pading">
                                          <span className="ps-text--order">Return Transaction Id</span>
                                        </div>
                                        <div className="col-6 text-right order-pading">
                                          <span className="ps-text--order title-font">
                                            {returnproductlist[0].refundtransactionid}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          {orderdetail.length > 0 &&
                            orderdetail[0].customernote !== null &&
                            orderdetail[0].customernote !== "" && (
                              <div className="col-12 pt-20 box-rounded width-100 mt-20 mb-20">
                                <div className="ps-cart__content">
                                  <div className="ps-cart__items order-summary bn">
                                    <div className="row ">
                                      <div className="col-12 mb-10">
                                        <h2 className="ps-form__title">Customer Note</h2>
                                      </div>
                                      <div className="col-12 title-font word-break-all mb-10">
                                        {orderdetail[0].customernote}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {orderdetail.length > 0 &&
                            orderdetail[0].orderhistorylist !== null &&
                            orderdetail[0].orderhistorylist !== "" && (
                              <div className="col-12 pt-20 box-rounded width-100 mt-20 mb-20">
                                <div className="ps-cart__content">
                                  <div className="ps-cart__items order-summary bn">
                                    <div className="row">
                                      <div className="col-12 mb-10">
                                        <h2 className="ps-form__title">Status</h2>
                                      </div>
                                      <div className="col-12">
                                        <ul className="timeline">
                                          <OrderHistoryStatus orderdetail={orderdetail} />
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="col-12 pt-10 pb-10 box-rounded width-100">
                            <div className="ps-cart__content">
                              <OrderSummary orderdetail={orderdetail} />
                            </div>
                          </div>

                          <ShippingBillingAddress orderdetail={orderdetail} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                errorcomp
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(OrderDetail);
