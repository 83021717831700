import { Tabs } from "antd";
import React from "react";

import ModuleDetailDescription from "../../../../components/elements/detail/modules/ModuleDetailDescription";
import ModuleDetailReviews from "../../../../components/elements/detail/modules/ModuleDetailReviews";
import ModuleDetailProductMeta from "../../../../components/elements/detail/modules/ModuleDetailProductMeta";

const { TabPane } = Tabs;
const ModuleDetailTabs = ({ product, productReview, productview }) => {

  const productMeta = product && product.productmeta;

  return (
    <Tabs defaultActiveKey="1" className="ps-product__tabs" id="productreview">
      <TabPane tab="Description" key="1">
        <ModuleDetailDescription product={product} />
      </TabPane>
      {productMeta && (
        <TabPane tab="Product Meta" key="3">
          <ModuleDetailProductMeta product={product} />
        </TabPane>
      )}
      <TabPane tab="Reviews" key="2">
        <ModuleDetailReviews productReview={productReview} product={product} productview={productview} />
      </TabPane>
    </Tabs>
  );
};

export default ModuleDetailTabs;
