import axios from "axios";

import config from '../config/config.json';

const headers = {
  Accept: "*/*",
};

/*
   To get blog categoty list
*/
const getBlogCategotyList = async () => {
  const url = config.apiUrl + config.apiurlname + "/blog/category";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To get blog list
*/
const getBloglist = async (categoryid, tagid) => {
  const url = config.apiUrl + config.apiurlname + "/blog";

  const body = {};
  if (categoryid !== undefined) {
    body.categoryid = categoryid;
  }
  if (tagid !== undefined) {
    body.tagid = tagid;
  }
  const res = await axios
    .post(url, body, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To get blog detail 
*/
const getBlogDetailById = async (blogid) => {
  var blogdata = new Object();
  blogdata["uniqueid"] = blogid;

  const url = config.apiUrl + config.apiurlname + "/blog" + blogid;
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: JSON.stringify(error) }));
  return res;
}

/*
   To get blog tag list
*/
const getBlogTagList = async () => {
  const url = config.apiUrl + config.apiurlname + "/blog/tags"
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
}

/*
  To get blog comment
*/
const getBlogCommentById = async (blogid, pageno, upperlimit, startindex) => {
  headers.Authorization = "Bearer" + localStorage.getItem("authToken");

  const url = `${config.apiUrl}${config.apiurlname}/blog/blogcomment/comments`;
  const data = {
    pageno: pageno,
    upperlimit: upperlimit,
    startindex: startindex,
    blogid: blogid
  };

  const res = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response.data.payload;
    })
    .catch((error) => ({ error: JSON.stringify(error) }));
  return res;
}

/*
  To save blog comment
*/
const saveBlogComment = async (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    Authorization: "Bearer " + authToken,
    "Content-Type": "application/json"
  };

  const url = config.apiUrl + config.apiurlname + "/blog/blogcomment";
  try {
    const response = await axios.post(url, data, { headers });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}



export default {
  getBlogCategotyList,
  getBloglist,
  getBlogDetailById,
  getBlogTagList,
  getBlogCommentById,
  saveBlogComment,
};