import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const ModuleBlogCategory = ({ blogcategorylist, tagId, categoryId }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const [selectedCategories, setSelectedCategories] = useState(searchParams.get('categoryid') || categoryId);

  useEffect(() => {
    setSelectedCategories(searchParams.get('categoryid') || categoryId);
  }, [categoryId]);

  const handleCategoryClick = async (categoryId) => {
    if (selectedCategories === categoryId) {
      setSelectedCategories("");
      searchParams.delete('categoryid');
      if (!tagId) {
        searchParams.delete('tagid');
      }
      window.location.href = `/blogs?${searchParams.toString()}`;
    } else {
      setSelectedCategories(categoryId);
      navigate(`/blogs?categoryid=${categoryId}${tagId ? `&tagid=${tagId}` : ''}`);
    }
  };


  return (<>
    {blogcategorylist && blogcategorylist !== null && blogcategorylist !== "" && blogcategorylist.length > 0 ? (<>
      <div className='blog-category'>
        <div className='mb-50'>
          <div className='blog-category-title mb-5'>
            <h3 className='title-text'>Categories</h3>
          </div>
          {blogcategorylist.map((item) =>
            <div className='blog-category-text' key={item.id} >
              <NavLink
                to={`/blogs?categoryid=${item.id}&tagid=${tagId || ''}`}
                className={`category-link ${selectedCategories === item.id ? 'selected-category' : ''}`}
                onClick={() => handleCategoryClick(item.id)}
              >
                {item.blogdisplayname} ({item.blogcount})
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </>) : (<></>)
    }
  </>
  )
}

export default ModuleBlogCategory