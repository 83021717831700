import React from 'react';
import parse from 'html-react-parser';

import ModuleBlogComment from './ModuleBlogComment';

const ModuleBlogDescription = ({ bloglist }) => {

  /*
    To replace spacial char from string
  */
  const renderContent = (content) => {
    if (!content) return null;
    let processedContent = content.replace("&lt;", "<").replace("&gt;", ">");
    const options = {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === "remove") {
          return null;
        }
      },
    };
    return parse(processedContent, options);
  };

  return (<>
    {bloglist && bloglist !== null && bloglist !== "" && bloglist.length > 0 ?
      bloglist.map((item) => <>
        <div className='blog-description'>
          <div className='card card-body'>
            <div className='text title-font shortdescription word-break-all'>
              <p className='mb-5'>{item.summary}</p>
              {renderContent(item.content)}
            </div>
            <div className='mt-10 '></div>
            <div className='module-comments mt-20 mb-20'>
              <ModuleBlogComment bloglist={bloglist[0].id} bloginfo={bloglist} />
            </div>
          </div>
        </div>
      </>) : (<></>)
    }
  </>
  )
}

export default ModuleBlogDescription