import axios from "axios";

import config from "../config/config.json";
import LocalstorageParam from "../pages/common/LocalstorageParam";
import Repository from "../repositories/Repository";

const headers = {
  Accept: "*/*",
};

/*
  To set local storage parameter in json
*/
var localparam = LocalstorageParam();

class ProductRepository {
  /*
   To get product list
  */
  async getProducts(data) {
    if (localparam !== "" && localparam.decoded.userid) {
      data.userid = localparam.decoded.userid;
    }

    const url = config.apiUrl + config.apiurlname + "/shop";
    const res = await axios
      .post(url, data, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });

    return res;
  }

  /*
   To get product detail by product id
  */
  async getProductsById(productid) {
    var productdata = new Object();
    productdata["uniqueid"] = productid;
    if (localparam !== "" && localparam.decoded.userid) {
      productdata["userid"] = localparam.decoded.userid;
    }

    const url = config.apiUrl + config.apiurlname + "/shop/productdetail";
    const reponse = await Repository.post(url, productdata, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To get product category
  */
  async getProductCategories(parentcategoryid) {
    var data = new Object();
    if (parentcategoryid) {
      data["parentcategory"] = parentcategoryid;
    }

    const url = config.apiUrl + config.apiurlname + "/category";
    const reponse = await Repository.post(url, data, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To get product by category id
  */
  async getProductByCategoryId(slug, pageno = 1, filters = {}) {
    try {
      const url = config.apiUrl + config.apiurlname + "/category/slug";

      const data = {
        slug,
        pageno: pageno,
        maximumprice: filters.maximumprice || "1000000",
        minimumprice: filters.minimumprice || "0",
        upperlimit: filters.upperlimit || 20,
        displayonlysale: filters.displayonlysale || "0",
        sortby: filters.sortby || null,
        category: filters.category || null,
        filters: filters.filters || null,
        rating: filters.rating || null,
        searchkey: filters.searchkey || null,
        tags: filters.tags || null,
      };

      const response = await axios.post(url, data, { headers });

      if (response && response.data) {
        return response.data.payload;
      }
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response);
        return error.response.data;
      } else {
        console.error("Error:", error);
      }
    }
  }

  /*
   To get product review
  */
  async getProductReviewById(uniqueid) {
    const url = config.apiUrl + config.apiurlname + "/shop/productreview/" + uniqueid;
    const reponse = await Repository.get(url, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To get product min-max price
  */
  async getProductMaxMin() {
    const url = config.apiUrl + config.apiurlname + "/shop/minmaxprice";
    const reponse = await Repository.get(url, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To save product review
  */
  async saveProductReview(data) {
    headers.Authorization = "Bearer " + localStorage.getItem("authToken");

    const url = config.apiUrl + config.apiurlname + "/productreview/save";
    const resp = await axios
      .post(url, data, { headers })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
    return resp;
  }

  /*
   To get filter group
  */
  async getFilterGroup() {
    const url = config.apiUrl + config.apiurlname + "/filtergroup";
    const reponse = await Repository.get(url, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To get product varient
  */
  async getProductVariant(uniqueid) {
    var productdata = new Object();
    productdata["uniqueid"] = uniqueid;
    if (localparam !== "" && localparam.decoded.userid) {
      productdata["userid"] = localparam.decoded.userid;
    }

    const url = config.apiUrl + config.apiurlname + "/shop/productvariant";
    const reponse = await Repository.post(url, productdata, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To get product rating
  */
  async getProductRating() {
    const url = config.apiUrl + config.apiurlname + "/productrating";
    const reponse = await Repository.get(url, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To get search product 
  */
  async getSearchProduct(data) {
    const url = config.apiUrl + config.apiurlname + "/shop/search";
    const reponse = await Repository.post(url, data, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To get category menu
  */
  async getCategoryMenu(data) {
    const url = config.apiUrl + config.apiurlname + "/category/menu";
    const reponse = await Repository.get(url, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return reponse;
  }

  /*
   To get shipping pincode
  */
  async getShippingPincode(pincode) {
    const url = config.apiUrl + config.apiurlname + "/checkserviceavailability?pincode=" + pincode;
    const response = await Repository.get(url, { headers })
      .then((response) => {
        return response.data.payload;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }
}

export default new ProductRepository();
