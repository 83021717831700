import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import cart from '../../api/cart';
import { PropTypes } from 'prop-types';

const PaymentMethod = ({ selectedPaymentMethod, setSelectedPaymentMethod }) => {
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const response = await cart.getActivePaymentGateWay();
        if (response.data.status === 200) {
          const paymentMethods = response.data.payload.data;
          setPaymentMethod(paymentMethods);

          if (paymentMethods.length > 0 && !selectedPaymentMethod) {
            setSelectedPaymentMethod(paymentMethods[0].paymentgatewayname);
          }
          setTimeout(
            function () {
              setIsLoading(false);
            }.bind(this),
            250
          );
        } else {
          setIsLoading(false);
          setPaymentMethod([]);
        }
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    }
    getPaymentMethods();
  }, [setSelectedPaymentMethod])

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {paymentMethod &&
        <h3 className="ps-form__title mb-30">Choose Payment Method</h3>}
      {paymentMethod && paymentMethod.map((method, index) => (<>
        <div className="row custom-row">
          <div key={index} className={`col-sm-8 paymentmethod-card-box-shadow m-3
                    ${selectedPaymentMethod === method.paymentgatewayname ? 'selected' : ''}`}
            onClick={() => {
              setSelectedPaymentMethod(method.paymentgatewayname);
            }
            }>
            <div className="card h-100 p-5 d-flex bn">
              <div className="d-flex row justify-content-between mx-2" >
                <div className='d-flex justify-content-between mx-2"'>
                  <input
                    type='radio'
                    name='paymentMethod'
                    value={method.paymentgatewayname}
                    // className='d-none'
                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    checked={selectedPaymentMethod === method.paymentgatewayname}
                  />
                  <img src={`/static/img/paymentmethod/${method.logo === "razorpay.jpg" ? "razorpay.png" : method.logo === "stripe.jpg" ? "stripe1.png" :
                    method.logo === "ccavenue.png" ? "ccavenue.png" :
                      method.logo === "phonepe.png" ? "phonepe.png" :
                        method.logo === "Paypal.jpg" ? "paypal.png" :
                          method.logo === "PayU.jpg" ? "payU1.png" :
                            `${method.paymentgatewayname}`}`}
                    className="card-img-top paymentmethod-image"
                  />
                </div>

                <div className='d-flex align-items-center col-10  '>
                  <h6 className='paymentmethod-text col-6 mb-0 mr-3'>Card Accepted</h6>
                  <span>
                    <img src={`/static/img/paymentmethod/${method.paymentgatewayname === "Razorpay" ? "razorpayCard.png" :
                      method.paymentgatewayname === "Stripe" ? "stripeCard.png" :
                        method.paymentgatewayname === "CCAvenue" ?
                          "ccavenueCard.png" :
                          method.paymentgatewayname === "Paypal" ? "paypalCards.png" :
                            method.paymentgatewayname === "PayU" ? "payUCard.png" :
                              method.paymentgatewayname === "PhonePe" ? "phonepeCard.png" :
                                `${method.paymentgatewayname}`
                      }`
                    }
                      className="paymentmethod-card" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      ))}
    </>
  )
}

PaymentMethod.propTypes = {
  selectedPaymentMethod: PropTypes.string.isRequired,
  setSelectedPaymentMethod: PropTypes.func.isRequired,
}

export default connect((state) => state)(PaymentMethod);