import React from "react";

const ModuleDetailVariant = ({ simple = false, array, setname, name, label, productid, singleVariant }) => {
  /*
    To set varient name
  */
  function handleSetActiveVariant(payload) {
    setname(payload);
  }

  return (
    <figure className="ps-product__sizes d-flex" >
      {!singleVariant && (
        <figcaption className="ps-product__label font-size-16 mr-10">{label}:</figcaption>
      )}
      <div className={`radio_container radio_container_${productid}`} >
        {array &&
          array
            .filter(item => item.name === name)
            .map((item) => (
              <React.Fragment key={`${name}-${item.value}`}>
                <input
                  type="radio"
                  name={name}
                  id={`${productid}-${name}-${item.value}`}
                  value={item.value}
                  onChange={() => handleSetActiveVariant(item.value)}
                />
                <label htmlFor={`${productid}-${name}-${item.value}`} className="title-font">
                  {item.value}
                </label>
              </React.Fragment>
            ))}
      </div>
    </figure>
  );
};

export default ModuleDetailVariant;
