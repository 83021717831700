import { Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import user from "../../api/user";
import NoDataFound from "../../components/common/NoDataFound";
import CartParameter from "../cart/CartParameter";

const AddressList = ({
  address,
  getAddressList,
  addressevent,
  sethandelisdefault,
  setAddressValues,
  displaydelete = true,
}) => {
  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();

  const [addresslist, setAddressList] = useState(address);

  /*
   To delete address by address id 
  */
  const deleteAddress = async (addressid) => {
    var addressobject = new Object();
    addressobject["addressid"] = addressid;

    const res = await user.deleteAddress(addressobject);
    if (res.data.status === 200) {
      setTimeout(() => {
        if (addressevent === "checkout") {
          window.location.href = "/checkout?coupon=" + searchparam.urlcoupon + "&rewardpoints=" + searchparam.urlpoints;
        } else {
          window.location.href = "/myaddress";
        }
      }, 500);
    } else {
      const modal = Modal.error({
        centered: true,
        title: "Error!",
        content: "Error while deleteing address",
      });
    }
  };

  /*
   To update default address
  */
  const isDefaultAddressUpdate = async (additem) => {
    var addressobject = new Object();
    addressobject["addressid"] = additem.id;

    const res = await user.isDefaultAddressUpdate(addressobject);
    if (res.data.status === 200) {
      if (addresslist) {
        let addresslistarray = [...addresslist];

        addresslistarray.forEach((item) => (item.isdefaultaddress = 0));

        let index = addresslistarray.findIndex((el) => el.id === additem.id);

        addresslistarray[index] = { ...addresslistarray[index], isdefaultaddress: "1" };

        setAddressList(addresslistarray);

        sethandelisdefault(additem.id);
        setAddressValues(JSON.stringify(additem), "CHANGED");
      }
    } else {
      const modal = Modal.error({
        centered: true,
        title: "Error!",
        content: "Error while changing default address",
      });
    }
  };

  /*
   To edit address, get address data by address id
  */
  const editAddress = async (addressid) => {
    getAddressList(addressid);
  };

  useEffect(() => {
    if (addresslist) {
      var defaultAdd = addresslist.filter((add) => add.isdefaultaddress == 1);
      setAddressValues(JSON.stringify(defaultAdd[0]));
    }
  }, [addresslist]);

  useEffect(() => {
    setAddressList(address);
  }, [address]);

  return (
    <>
      {addresslist && addresslist.length > 0 ? (
        <>
          {addresslist.map((item) => {
            return (
              <div
                key={item.id}
                className={
                  addressevent === "orderaddress"
                    ? "col-12 col-md-12  mb-30 pl-0 pr-0 transition-none"
                    : "col-12 col-md-6  mb-30 transition-none"
                }
              >
                <div className="card card-box-shadow bn">
                  <div className="card-header background-color-white p-5">
                    <div className="card-title">
                      <div className="display-flex address-card">
                        {addressevent === "checkout" && (
                          <span className="pr-10">
                            <input
                              type="radio"
                              name="isdefaultaddress"
                              label="Is Default"
                              className="radio-button"
                              id={"address_" + item.id}
                              checked={item.isdefaultaddress == "1" ? true : false}
                              onClick={(e) => {
                                isDefaultAddressUpdate(item);
                              }}
                            />
                          </span>
                        )}
                        <span className="address-name">
                          <span>{item.fullname}</span>
                        </span>

                        <span className="address-button btnaddress ">
                          <span className="tag tag-success address-type">
                            {item.addresstype === "Home" ? "Home" : "Office"}
                          </span>
                          {addressevent !== "orderaddress" && (
                            <>
                              {displaydelete && (
                                <span className="pl-5">
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteAddress(item.id);
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </span>
                              )}
                              <span className="pl-10">
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    editAddress(item.id);
                                  }}
                                >
                                  <i className="fa fa-pencil"></i>
                                </a>
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="title-font word-break-all">
                        {item.address} {item.city} {item.statename} {item.countryname}
                        {" - "}
                        {item.pincode}
                      </p>
                      <p>
                        <span className="dark-blue">Mobile No :</span>
                        <span className="title-font">
                          {item.addressphonecountry} {item.addressphonenumber}
                        </span>
                      </p>

                      <p>
                        <span className="dark-blue">Company Name :</span>
                        <span className="title-font word-break-all">{item.companyname}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <NoDataFound component={"address"} />
      )}
    </>
  );
};

export default AddressList;
