const NoDataFound = ({ component }) => {
  return (
    <div className="col-12 col-md-12  text-center">
      <div className="ant-result ant-result-warning">
        <div className="ant-result-icon">
          <span
            role="img"
            aria-label="warning"
            className={
              component === "productreview"
                ? "anticon anticon-warning color-blue font-size-25"
                : "anticon anticon-warning color-blue"
            }
          >
            {component === "address" && <i className="fi fi-rr-land-layer-location"></i>}
            {component === "product" && <i className="fi fi-rr-box-open-full"></i>}
            {component === "cart" && <i className="fi fi-rr-shopping-cart"></i>}
            {component === "order" && <i className="fi fi-rr-bags-shopping"></i>}
            {component === "wishlist" && <i className="fi fi-rr-heart font-size-60"></i>}
            {component === "blog" && <i className="fi fi-rr-blog-text blog-icon"></i>}
            {component === "productreview" && (
              <>
                <i className="fa fa-star-o "></i> <i className="fa fa-star-o"></i> <i className="fa fa-star-o"></i>{" "}
                <i className="fa fa-star-o"></i> <i className="fa fa-star-o"></i>
              </>
            )}
            {component === "Order History" && <i className="fa fa-history"></i>}
          </span>
        </div>
        <div className="ant-result-title">
          {component === "address" && "No address found."}
          {component === "product" && "No product found."}
          {component === "cart" && "Your cart is empty."}
          {component === "order" && "No order found."}
          {component === "wishlist" && "Your wish list is empty"}
          {component === "blog" && "No blog found."}
          {component === "productreview" && "No review found."}
          {component === "Order History" && "No order status history found"}
        </div>
      </div>
    </div>
  );
};
export default NoDataFound;
