import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
import { Button, Modal } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";

import config from "../../../../../config/config.json";
import PrevArrow from "../../../../elements/carousel/PrevArrow";
import NextArrow from "../../../../elements/carousel/NextArrow";
import shopconfig from "./../../../../../config/shopconfig.json";

const CustomerReviewOne = ({ customerreview }) => {
  const [show, setShow] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  let imagepath = config.apiUrl + config.image_path;
  let nouserImage = "/static/img/static_image/user_image.png";

  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 5,
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const handleClose = () => {
    setShow(false);
    setSelectedReview(null);
  };

  const handleShow = (customerreview) => {
    setSelectedReview(customerreview);
    setShow(true);
  };

  return (
    <>
      <div className="review-option--one">
        <div className="container">
          <div className="row pb-20 ph-10 title-row">
            <div className="col-8">
              <h3 className="section-main-title">Customer Reviews</h3>
            </div>
            <div className="col-4 title-action">
              <a href={shopconfig.review_url}>
                <div className="d-flex">
                  <p className="mr-10">View All Reviews</p>
                  <i className="fi fi-rr-arrow-circle-right"></i>
                </div>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-100">
              <div className="container p-0">
                <div className="review-card-container">
                  <div className="container p-0">
                    <Slider {...carouselSetting} className="ps-carousel home-slider">
                      {customerreview &&
                        customerreview.length > 0 &&
                        customerreview.map((review) => (
                          <>
                            <div key={review.id} className="review-card" onClick={() => handleShow(review)}>
                              <div className="card-header">
                                <div className="review-image">
                                  <LazyLoad>
                                    <img
                                      src={review.imagename ? `${imagepath}${review.imagename}` : nouserImage}
                                      alt="Customer"
                                    />
                                  </LazyLoad>
                                </div>
                                <div className="reviewer-details">
                                  <p className="reviewer-name">
                                    {review.firstname} {review.lastname}
                                  </p>
                                  <p className="reviewer-rating">
                                    <Rating allowFraction initialValue={review.rating} readonly size={18} />
                                  </p>
                                </div>
                              </div>
                              <div className="review-content h-100">
                                <h5>{review.title ? review.title : "ABC treks with Global Treks Guide"}</h5>
                                <p>{review.review}</p>
                                <p className="review-date">{review.reviewdate}</p>
                              </div>
                            </div>
                          </>
                        ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal show={show} onHide={handleClose} centered scrollable className="review-option-one--modal pl-0">
              <Modal.Header className="modal-header" closeButton>
                <h3>Customer Review</h3>
              </Modal.Header>

              <Modal.Body className="modal-body">
                {selectedReview && (
                  <>
                    <div className="d-flex justify-content-between ">
                      <div className="reviewer-info ml-10 mt-15">
                        <h4>
                          {selectedReview.firstname} {selectedReview.lastname}
                        </h4>
                        <p className="date">{selectedReview.reviewdate}</p>
                        <p className="rating">
                          <Rating allowFraction initialValue={selectedReview.rating} readonly size={20} />
                        </p>
                      </div>
                      <div>
                        <div className="reviewer-image">
                          <img
                            src={selectedReview.imagename ? `${imagepath}${selectedReview.imagename}` : nouserImage}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="review-content">
                      <h5 className="text-center">
                        {selectedReview.title ? selectedReview.title : "ABC treks with Global Treks Guide"}
                      </h5>
                      <span>
                        <img src="/static/img/home/reviews/inverted-commas.png" className="comma-img-start" />
                      </span>
                      <p className="content">{selectedReview.review}</p>
                      <span>
                        <img src="/static/img/home/reviews/inverted-commas_.png" className="comma-img-end" />
                      </span>
                    </div>
                  </>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button className="ps-btn ps-btn--warning close-btn" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerReviewOne;
