import React from "react";
import { Link } from "react-router-dom";

import config from "../../../config/config.json";
import LocalstorageParam from "../../../pages/common/LocalstorageParam";
import myaccountMenu from "../../../public/static/data/myaccountMenu.json";
import MyAccountMenu from "../../elements/menu/MyAccountMenu";

const NavigationLeft = (menuname) => {
  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  let username = "";
  if (
    localparam !== "" &&
    localparam.decoded !== "" &&
    localparam.decoded.firstname !== null &&
    localparam.decoded.firstname !== ""
  ) {
    username = localparam.decoded.firstname + " " + localparam.decoded.lastname;
  }

  /*
    To toggle up and down
  */
  let imageURL = "/static/img/static_image/user_image.png";
  const showFilters = () => {
    if (document.getElementById("up")) {
      document.getElementById("up").classList.toggle("fade");
      document.getElementById("down").classList.toggle("fade");
      document.getElementById("myaccountmenu").classList.toggle("fade");
      document.getElementById("menunamediv").classList.toggle("profile-menu-mobile");
      document.getElementById("menuname").classList.toggle("fade");
    }
  };

  const filteredMenu = myaccountMenu.myaccount_menu.filter((item) => {
    if (item.text === "Reward Points") {
      return config.isdisplayrewardpoints == 1;
    }
    return true;
  });

  /*
    To view
  */
  let menuView;
  menuView = filteredMenu.map((item) => (
    <li key={item.text} className={item.text === menuname.menuname ? "active nav-item" : "nav-item title-font"}>
      <Link to={item.url} className={item.text === menuname.menuname ? "nav-link active" : "nav-link title-font"}>
        <span className="pr-15">{item.icon ? <i className={item.icon}></i> : ""}</span>
        <span className="title-font">{item.text}</span>
      </Link>
    </li>
  ));

  return (
    <div className="width-100">
      <div className="profile-sidebar card-box-shadow mobile-hide" id="leftmenu">
        <div className="profileimage ">
          <img src={imageURL} className="img-responsive profile-user-img" alt="" />
        </div>
        <div className="profile-usertitle ">
          <div className="profile-usertitle-name">{localparam !== "" && username ? username : ""}</div>
        </div>
        <div className="profile-usermenu sidebar-sticky">
          <MyAccountMenu source={filteredMenu} className="nav flex-column" menuname={menuname} />
        </div>
      </div>
      <div className="profile-sidebar mobile-show card-box-shadow mobile-menu-card mb-30">
        <div className="profile-usermenu ml-0 ">
          <a
            onClick={(e) => {
              showFilters();
            }}
          >
            <div className="profile-menu-mobile pb-15" id="menunamediv">
              <span className="title-font">
                <span className="pr-15">
                  <img src={imageURL} className="profile-image" alt="" />
                </span>
                <span className="profile-usertitle-name title-font">
                  {localparam !== "" && username ? username : ""}
                </span>
              </span>
              <span className="pull-right openmenu fade" id={`up`}>
                <i className="fa fa-angle-up pt-10"></i>
              </span>
              <span className="pull-right openmenu " id={`down`}>
                <i className="fa fa-angle-down pt-10"></i>
              </span>
            </div>
          </a>
          <ul className={"nav flex-column openmenu fade"} id={"myaccountmenu"}>
            {menuView}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavigationLeft;
