import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ProductOnCart from "../../components/elements/products/ProductOnCart";
import { currencyPrice } from "../../hooks/currencyCalculation";
import useEcomerce from "../../hooks/useEcomerce";
import { toggleDrawer } from "../../store/app/action";
import { setCartItems } from "../../store/ecomerce/action";
import { calculateAmount, calculateCartQuantity } from "../../utilities/ecomerce-helpers";
import NoDataFound from "../common/NoDataFound";

const EcomerceMiniCart = ({ ecomerce }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { removeItem } = useEcomerce();

  const { cart_list, removeItems, getCartlist } = useEcomerce();
  const { currencyrate, getcurrencyRate } = useEcomerce();

  /*
    To close model and redirect to shop page 
  */
  function handleCloseDrawer(e) {
    e.preventDefault();
    navigate("/shop");
    dispatch(toggleDrawer(false));
    document.body.classList.add("overflow-auto");
  }

  /*
    To remove cart product
  */
  function handleRemoveCart(e) {
    e.preventDefault();
    removeItems("cart");
    document.body.classList.add("overflow-auto");
  }

  /*
    To redirect to cart page 
  */
  function handleMoveCart(e) {
    e.preventDefault(e);
    dispatch(toggleDrawer(false));
    navigate("/cart");
    document.body.classList.add("overflow-auto");
  }

  /*
    To redirect to checkout page 
  */
  function handleMoveChackout(e) {
    e.preventDefault(e);
    dispatch(toggleDrawer(false));
    navigate("/checkout");
    document.body.classList.add("overflow-auto");
  }

  useEffect(() => {
    getcurrencyRate();
    getCartlist(ecomerce.cartItems, "cart");
  }, [ecomerce]);

  /*
    To remove from cart 
  */
  function handleRemoveItem(e, cartid) {
    e.preventDefault();
    removeItem({ cartid: cartid }, cart_list, "cart");
    getCartlist();
    dispatch(setCartItems(cart_list));
  }

  /*
    To view 
  */
  let cartItemsView, cartActionsView, cartQuantityView, cartAmountView;
  var flag = true;
  if (cart_list && cart_list.length > 0) {
    let i = 0;
    cart_list.forEach((element) => {
      var price = element.price;
      if (Number(element.offerprice) > 0) {
        price = element.offerprice;
      }
      cart_list[i].mainprice = price;
      if (flag) {
        if (Number(element.stock) < Number(element.quantity)) {
          flag = false;
        }
      }
      i++;
    });

    cartAmountView = calculateAmount(cart_list);
    cartQuantityView = calculateCartQuantity(cart_list);
    const items = cart_list.map((item) => (
      <div className="ps-cart__item card-box-shadow bn mb-10 pt-10" key={item.id}>
        <div className="text-right pr-10">
          <a
            href="#"
            className="ps-product__remove-item color-red font-weight-100"
            onClick={(e) => handleRemoveItem(e, item.id)}
          >
            <strong className="font-weight-100 ">Remove</strong>
          </a>
        </div>
        <ProductOnCart product={item} cartevent="minicart" currencyrate={currencyrate}>
          <p className="ps-product__meta">
            <span>{item.quantity} x item</span>
          </p>
        </ProductOnCart>
        {Number(item.stock) === 0 ? (
          <h6 className="color-red outstock text-left font-weight-normal ml-95 ">Sold Out</h6>
        ) : Number(item.quantity) > Number(item.stock) ? (
          <h6 className="color-red outstock text-left font-weight-normal ml-95">
            Max allowed qty is {Number(item.stock)}
          </h6>
        ) : (
          ""
        )}
      </div>
    ));
    cartItemsView = <div className="ps-cart__items bn ">{items}</div>;
    cartActionsView = (
      <>
        <div className="ps-cart__summary display-unset ">
          <div className="ps-cart__total display-unset">
            <div className="row mb-20">
              <div className="col-6 text-left">
                <h4>
                  <strong>Total</strong>
                </h4>
              </div>
              <div className="col-6 text-right">
                <h4>
                  <strong className="title-font">{currencyPrice(cartAmountView, currencyrate)}</strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="ps-cart__actions">
          <a href="javascript:;" onClick={(e) => handleMoveCart(e)} className="ps-btn ps-btn--primary">
            View Cart
          </a>
          {flag ? (
            <a href="javascript:;" onClick={(e) => handleMoveChackout(e)} className="ps-btn ps-btn--primary">
              Checkout
            </a>
          ) : (
            <a href="javascript:;" className="ps-btn ps-btn--primary">
              Checkout
            </a>
          )}
        </div>
      </>
    );
  } else {
    cartItemsView = <NoDataFound component={"cart"} />;
    cartActionsView = (
      <div>
        <a href="/shop" className="ps-btn ps-btn--primary" onClick={(e) => handleCloseDrawer(e)}>
          Back to shop
        </a>
      </div>
    );
  }

  return (
    <div className="ps-cart--simple">
      <div className="ps-cart__header">
        <div className="row">
          <div className="col-9 pr-0">
            <h3>
              Shopping Cart
              <sup>({cartQuantityView ? cartQuantityView : "0"})</sup>
            </h3>
          </div>
          {cart_list && cart_list.length > 0 && (
            <div className="col-3 pl-0">
              <div className="ps-cart__clear-cart">
                <a href="#" className="color-blue" onClick={(e) => handleRemoveCart(e)}>
                  Clear all
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="ps-cart__content text-center">
        {cartItemsView}
        {cartActionsView}
      </div>
    </div>
  );
};

export default connect((state) => state)(EcomerceMiniCart);
