import { Modal } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import user from "../../api/user";
import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import LocalstorageParam from "../common/LocalstorageParam";
import FormButton from "../../components/elements/form/FormButton";

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "My Account",
    url: "/profile",
  },
  {
    id: 3,
    text: "Reset Password",
  },
];

/*
  To set initial values
*/
const initialValues = {
  oldpassword: "",
  newpassword: "",
  confirmpassword: ""
};

/*
  To form validation using yup
*/
const validationSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .required("Please enter value for Current Password")
    .matches(/^[a-zA-Z0-9!@#$%^&*]{0,50}$/, "Please enter valid Current Password"),
  newpassword: Yup.string()
    .required("Please enter value for New Password")
    .matches(/^[a-zA-Z0-9!@#$%^&*]{0,50}$/, "Please enter valid New Password"),
  confirmpassword: Yup.string()
    .required("Please enter value for Confirm New Password")
    .matches(/^[a-zA-Z0-9!@#$%^&*]{0,50}$/, "Please enter valid Confirm New Password")
});

const PasswordChange = () => {
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  useEffect(() => {
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
      }, 500);
    } else {
      navigate("/login");
    }

    window.scrollTo(0, 0);
  }, []);

  /*
    To change password
  */
  const changePassword = async (data) => {
    setDisable(true);
    document.getElementById("errormsg").classList.add("hide");
    document.getElementById("errormsg").innerHTML = "";

    if (data.newpassword === data.confirmpassword) {
      const res = await user.changePassword(data)
      if (res.data) {
        if (res.data.status === 200) {
          setDisable(false);
          document.getElementById("errormsg").classList.remove("hide");
          document.getElementById("errormsg").innerHTML = "";
          const modal = Modal.success({
            centered: true,
            title: "Success!",
            content: "Password Change successfully",
          });
        } else {
          setDisable(false);
          document.getElementById("errormsg").classList.remove("hide");
          document.getElementById("errormsg").innerHTML = res.data.errors.message;
        }
      } else {
        setDisable(false);
      }
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = "New Password should match Confirm New Password";
    }
  };


  /*
    To remove error message
  */
  const resetError = () => {
    document.getElementById("errormsg").classList.remove("hide");
    document.getElementById("errormsg").innerHTML = "";
  };
  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Reset Password"} />
                </div>
                <div className="ps-layout__right">
                  <div className="ps-page__content">
                    <div className="row">
                      <div className="col-12 col-md-12 ps-form--review">
                        <h2 className="ps-form__title mb-20">Reset Password</h2>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={(values, { resetForm }) => {
                            changePassword(values);
                            resetForm();
                          }}
                        >
                          {(formik) => {
                            const { errors, touched } = formik;
                            if (errors.oldpassword || errors.newpassword || errors.confirmpassword) {
                              resetError();
                            }
                            return (
                              <Form className="p-30 box-rounded width-100">
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <div className="ps-form__group form-group">
                                      <label className="ps-form__label">Current Password *</label>
                                      <div className="input-group">
                                        <Field
                                          className={
                                            errors.oldpassword && touched.oldpassword
                                              ? "input-error form-control ps-form__input"
                                              : "form-control ps-form__input"
                                          }
                                          name={"oldpassword"}
                                          type={"password"}
                                          placeholder={"Current Password"}
                                          maxLength={"50"}
                                          id="oldpassword"
                                          onKeyUp={resetError}
                                          onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                              e.target.blur();
                                            }
                                          }}
                                        />
                                      </div>
                                      <ErrorMessage name="oldpassword" component="span" className="color-red" />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <div className="ps-form__group form-group">
                                      <label className="ps-form__label">New Password *</label>
                                      <div className="input-group">
                                        <Field
                                          className={
                                            errors.newpassword && touched.newpassword
                                              ? "input-error form-control ps-form__input"
                                              : "form-control ps-form__input"
                                          }
                                          name={"newpassword"}
                                          type={"password"}
                                          placeholder={"New Password"}
                                          maxLength={"50"}
                                          id="newpassword"
                                          onKeyUp={resetError}
                                          onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                              e.target.blur();
                                            }
                                          }}
                                        />
                                      </div>
                                      <ErrorMessage name="newpassword" component="span" className="color-red" />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <div className="ps-form__group form-group">
                                      <label className="ps-form__label">Confirm New Password *</label>
                                      <div className="input-group">
                                        <Field
                                          className={
                                            errors.confirmpassword && touched.confirmpassword
                                              ? "input-error form-control ps-form__input"
                                              : "form-control ps-form__input"
                                          }
                                          name={"confirmpassword"}
                                          type={"password"}
                                          placeholder={"Confirm New Password"}
                                          maxLength={"50"}
                                          id="confirmpassword"
                                          onKeyUp={resetError}
                                          onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                              e.target.blur();
                                            }
                                          }}
                                        />
                                      </div>
                                      <ErrorMessage name="confirmpassword" component="span" className="color-red " />
                                    </div>
                                  </div>
                                </div>
                                <span className="color-red hide" id="errormsg"></span>

                                <div className="ps-form__submit mt-10">
                                  <FormButton
                                    name={"Update"}
                                    type="submit"
                                    className={
                                      disable
                                        ? "ps-btn ps-btn--warning width-120 disabled"
                                        : "ps-btn ps-btn--warning width-120"
                                    }
                                  />

                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default PasswordChange;
