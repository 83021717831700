import React from 'react';

const ModuleDetailProductMeta = ({ product }) => {

  let productMeta;
  try {
    let newProductMeta = product.productmeta;
    if (newProductMeta.startsWith('{[') && newProductMeta.endsWith(']}')) {
      newProductMeta = newProductMeta.substring(1, newProductMeta.length - 1);
    }
    productMeta = JSON.parse(newProductMeta);
  } catch (error) {
    console.error("Error parsing product.meta:", error);
    productMeta = [];
  }

  return (<>
    <div className="ps-product__description ps-document card-box-shadow bn">
      <div className="row">
        <div className="col-sm-12 m-0">
          <table className='prodcutmeta-table text-capitalize title-font productdescription table-responsive'>
            {productMeta && productMeta.length > 0 && productMeta.map((item, index) =>
              <tr key={index} className='row'>
                <td className='col-sm-3 word-break-all key'>{item.label}</td>
                <td className='col-sm-9 title-font word-break-all value'>{item.value}</td>
              </tr>
            )}
          </table>
        </div>
      </div>
    </div>
  </>);
}

export default ModuleDetailProductMeta;