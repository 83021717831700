import { Button, Drawer, Slider } from "antd";
import React, { useEffect, useState } from "react";

import useFilter from "../../../hooks/useFilter";
import { Link, useNavigate, useParams } from "react-router-dom";
import useHeader from "../../../hooks/useHeader";

const FilterDrawer = ({ filtergroup, category }) => {
  const {
    searchParams,
    isMenu,
    showFilters,
    dropdowns,
    closeFilters,
    toggleDropdown,
    ratingData,
    priceRange,
    handlePriceChange,
    clearAllFilters,
    checkedFilters,
    setCheckedFilters,
    selectedRatings,
    setSelectedRatings,
    handleRatingChange,
    handleCheckboxChange,
    applyFilter,
  } = useFilter();
  const { url } = useHeader();
  const { categoryid } = useParams();
  const navigate = useNavigate();

  const [filtersApplied, setFiltersApplied] = useState(false);
  const [urlFiltersState, setUrlFiltersState] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(categoryid);

  useEffect(() => {
    const urlFilters = searchParams.get("filters") ? searchParams.get("filters").split(",") : [];

    const urlRatings = searchParams.get("rating") ? searchParams.get("rating").split(",").map(Number) : [];

    const urlCategory = categoryid ? [categoryid] : [];

    const urlMinPrice = searchParams.get("minimumprice") ? Number(searchParams.get("minimumprice")) : 0;
    const urlMaxPrice = searchParams.get("maximumprice") ? Number(searchParams.get("maximumprice")) : 1000000;

    // Update checked filters state from URL
    const initialCheckedFilters = (filtergroup || []).reduce((acc, group) => {
      const filterName = group.displayname;
      const subFilters = group.filterlist.reduce((subAcc, filter) => {
        subAcc[filter.id] = urlFilters.includes(filter.id.toString());
        return subAcc;
      }, {});
      acc[filterName] = subFilters;
      return acc;
    }, {});

    setCheckedFilters(initialCheckedFilters);
    setSelectedRatings(urlRatings);
    handlePriceChange([urlMinPrice, urlMaxPrice]);

    const allUrlFilters = [...urlFilters, ...urlCategory, ...urlRatings.map(String)];

    setUrlFiltersState(allUrlFilters);

    // Ensure that the URL is updated on page load with the initial values
    const queryParams = new URLSearchParams(searchParams);

    if (urlFilters.length > 0) {
      queryParams.set("filters", urlFilters.join(","));
    } else {
      queryParams.delete("filters");
    }

    if (urlRatings.length > 0) {
      queryParams.set("rating", urlRatings.join(","));
    } else {
      queryParams.delete("rating");
    }

    queryParams.set("minimumprice", urlMinPrice.toString());
    queryParams.set("maximumprice", urlMaxPrice.toString());

    const queryString = queryParams.toString().replace(/%2C/g, ",");
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.pushState(null, "", newUrl);
  }, [isMenu, filtergroup, searchParams, categoryid, setCheckedFilters, setSelectedRatings, setSelectedCategories]);

  useEffect(() => {
    let totalFilters = 0;

    const categoryFilter = selectedCategories ? [1] : "";

    Object.entries(checkedFilters).forEach(([filterName, filterStates]) => {
      totalFilters += Object.values(filterStates).filter((checked) => checked).length;
    });

    totalFilters += selectedRatings.length;

    if (priceRange[0] > 0 || priceRange[1] < 1000000) {
      totalFilters += 1;
    }

    const appliedFilterIds = Object.values(checkedFilters).flatMap((filters) =>
      Object.entries(filters)
        .filter(([id, checked]) => checked)
        .map(([id]) => id)
    );

    const allFilters = [
      ...appliedFilterIds,
      ...categoryFilter,
      ...selectedRatings.map(String),
      priceRange[0] > 0 || priceRange[1] < 1000000 ? `price:${priceRange[0]}-${priceRange[1]}` : null,
    ].filter(Boolean);

    setUrlFiltersState(allFilters);

    if (categoryid !== null && category !== undefined) {
      setSelectedCategories(categoryid);
    }
    if (url.startsWith("/shop")) {
      setSelectedCategories("");
    }
  }, [checkedFilters, selectedRatings, categoryid, priceRange, searchParams, filtersApplied, selectedCategories]);

  const filterData =
    filtergroup && Array.isArray(filtergroup)
      ? filtergroup.map((group) => ({
          name: group.displayname,
          subFilters: group.filterlist.map((filter) => ({
            id: filter.id,
            label: filter.filtername,
            count: null,
          })),
        }))
      : [];

  const handleApplyFilter = () => {
    applyFilter();
    setFiltersApplied(true);
    searchParams.set("pageno", "1");
  };

  const handleCategoryChange = (e, catId) => {
    e.stopPropagation();
    setSelectedCategories((prevSelectedCategory) => {
      if (prevSelectedCategory === catId) {
        setSelectedCategories("");
        return "";
      } else {
        return catId;
      }
    });
  };

  return (
    <>
      <div className="shop-filter">
        <Button className="filter-button" onClick={showFilters}>
          <i class="fi fi-rr-filter"></i>
          {urlFiltersState.length > 0 && <span className="badge badge-span">{urlFiltersState.length}</span>}
        </Button>
      </div>
      {isMenu ? (
        <Drawer
          className="ps-panel--mobile mobile-home"
          closable={false}
          placement="right"
          onClose={closeFilters}
          visible={isMenu}
          width={450}
        >
          <div className="filter-drawer">
            <div className="filter-header">
              <div className="filter-drawer_close">
                <i className="icon-cross" onClick={closeFilters}></i>
              </div>
              <div className="filter-drawer_title">
                <h2>Filter By</h2>
                <p
                  onClick={() => {
                    clearAllFilters();
                    setFiltersApplied(false);
                    handleCheckboxChange("");
                    setSelectedCategories("");
                    navigate("/shop");
                  }}
                >
                  Clear All
                </p>
              </div>
            </div>
            <div className="filter-drawer_filter-container">
              {/* Category Filter */}
              <div>
                <div className="filter-drawer_filter-name" onClick={() => toggleDropdown("Category")}>
                  <div>
                    <span className="filter-name">Category</span>
                    {selectedCategories && <span className="badge filter-badge">1</span>}
                  </div>
                  <span>
                    <i className={`icon-chevron-${dropdowns["Category"] ? "up" : "down"}`}></i>
                  </span>
                </div>
                {dropdowns["Category"] && (
                  <div className="dropdown-filter-container">
                    <div className="category-filter-container">
                      {category.map((cat) => (
                        <Link
                          to={
                            cat.url === selectedCategories
                              ? `/shop?${searchParams.toString()}`
                              : `/category/${cat.url}?${searchParams.toString()}`
                          }
                          key={cat.id}
                        >
                          <div className="category-item" key={cat.id} onClick={() => handleCategoryChange(cat.url)}>
                            <input
                              type="checkbox"
                              name="category"
                              className="mr-20"
                              checked={cat.url === selectedCategories}
                              onChange={() => handleCategoryChange(cat.url)}
                              onClick={() => handleCategoryChange(cat.url)}
                            />
                            <div>{cat.categoryname}</div>
                            <span className={`${cat.productcount.length > 0 ? "count" : "remove-count"}`}>
                              {cat.productcount}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {/* End of Category Filter */}
              {filterData &&
                filterData.map((filter) => {
                  const appliedFiltersCount = Object.values(checkedFilters[filter.name] || {}).filter(
                    (checked) => checked
                  ).length;

                  return (
                    <div key={filter.name}>
                      <div className="filter-drawer_filter-name" onClick={() => toggleDropdown(filter.name)}>
                        <div>
                          <span className="filter-name">{filter.name}</span>
                          {appliedFiltersCount > 0 && <span className="badge filter-badge">{appliedFiltersCount}</span>}
                        </div>
                        <span>
                          <i className={`icon-chevron-${dropdowns[filter.name] ? "up" : "down"}`}></i>
                        </span>
                      </div>
                      {dropdowns[filter.name] && (
                        <div
                          className={`dropdown-filter-container ${
                            filter.name === "Size" ? "size-filter-container" : ""
                          }`}
                        >
                          {filter.name === "Size" ? (
                            <div className="size-options">
                              <div className="size-grid">
                                {filter.subFilters.map((subFilter) => (
                                  <div
                                    key={subFilter.id}
                                    className={`size-item ${
                                      checkedFilters[filter.name]?.[subFilter.id] ? "selected-size" : ""
                                    }`}
                                    onClick={() => handleCheckboxChange(filter.name, subFilter.id)}
                                  >
                                    <input
                                      type="checkbox"
                                      className="btn-check"
                                      checked={checkedFilters[filter.name]?.[subFilter.id]}
                                      onChange={() => handleCheckboxChange(filter.name, subFilter.id)}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    <label
                                      className="btn btn-size"
                                      for="btn-check-4"
                                      onClick={() => handleCheckboxChange(filter.name, subFilter.id)}
                                    >
                                      {subFilter.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            filter.subFilters.map((subFilter) => (
                              <div className="filter-content-container w-100" key={subFilter.id}>
                                <div>
                                  <input
                                    type="checkbox"
                                    checked={checkedFilters[filter.name]?.[subFilter.id]}
                                    onChange={() => handleCheckboxChange(filter.name, subFilter.id)}
                                  />
                                </div>
                                <div
                                  className="name-count-container w-100"
                                  onClick={() => handleCheckboxChange(filter.name, subFilter.id)}
                                >
                                  <div>{subFilter.label}</div>
                                  <span className={`${subFilter.count ? "count" : "remove-count"}`}>
                                    {subFilter.count}
                                  </span>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              {/* Price Filter */}
              <div>
                <div className="filter-drawer_filter-name" onClick={() => toggleDropdown("Price")}>
                  <div>
                    {" "}
                    <span className="filter-name">Price</span>
                    {(priceRange[0] > 0 || priceRange[1] < 1000000) && <span className="badge filter-badge">1</span>}
                  </div>
                  <span>
                    <i className={`icon-chevron-${dropdowns["Price"] ? "up" : "down"}`}></i>
                  </span>
                </div>
                {dropdowns["Price"] && (
                  <div className="price-filter-container">
                    <Slider range min={0} max={1000000} value={priceRange} onChange={handlePriceChange} />
                    <div className="price-inputs">
                      <input type="text" value={priceRange[0]} readOnly />
                      <span> to </span>
                      <input type="text" value={priceRange[1]} readOnly />
                    </div>
                  </div>
                )}
              </div>
              {/* End of Price Filter */}
              {/* Rating Filter */}
              <div>
                <div className="filter-drawer_filter-name" onClick={() => toggleDropdown("Rating")}>
                  <div>
                    <span className="filter-name">Rating</span>
                    {selectedRatings.length > 0 && <span className="badge filter-badge">{selectedRatings.length}</span>}
                  </div>
                  <span>
                    <i className={`icon-chevron-${dropdowns["Rating"] ? "up" : "down"}`}></i>
                  </span>
                </div>
                {dropdowns["Rating"] && (
                  <div className="rating-filter-container">
                    {ratingData.map((ratingItem, index) => (
                      <div className="rating-item" key={index} onClick={() => handleRatingChange(ratingItem.rating)}>
                        <input
                          type="checkbox"
                          name="rating"
                          className="mr-20"
                          checked={selectedRatings.includes(ratingItem.rating)}
                          onChange={() => handleRatingChange(ratingItem.rating)}
                          onClick={(e) => e.stopPropagation()}
                        />
                        {[...Array(ratingItem.rating)].map((_, index) => (
                          <i key={index} className="fa fa-star" style={{ color: "#FF9600" }}></i>
                        ))}
                        {[...Array(5 - ratingItem.rating)].map((_, index) => (
                          <i key={index} className="fa fa-star" style={{ color: "#dcdcdc" }}></i>
                        ))}
                        <div>
                          <span className="count">({ratingItem.productcount})</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* End of Rating Filter */}
            </div>
            <div className="filter-footer">
              <button className="" onClick={handleApplyFilter}>
                Apply Filter
              </button>
            </div>
          </div>
        </Drawer>
      ) : (
        ""
      )}
    </>
  );
};

export default FilterDrawer;
