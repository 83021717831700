import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Filters from "./Filters";
import { UrlProduct } from "../../../pages/product/LoadProduct";

const WidgetShopFilterByBrands = ({ filtergroup }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filtergroups, setFilterGroups] = useState([]);

  // Extract filters from URL
  let filtersFromURL = searchParams.get("filters") ? searchParams.get("filters").split(',').map(Number) : [];

  /*
    To toggle filter group visibility
  */
  const showFilters = (filtergroupname) => {
    const filterElement = document.getElementById(filtergroupname);
    if (filterElement) {
      filterElement.classList.toggle("fade");
      document.getElementById("up_" + filtergroupname).classList.toggle("fade");
      document.getElementById("down_" + filtergroupname).classList.toggle("fade");
    }
  };

  useEffect(() => {
    setFilterGroups(filtersFromURL);
    updateCheckboxStates(filtersFromURL);
  }, [searchParams]);

  /*
    Function to update the checkbox states based on URL filters
  */
  const updateCheckboxStates = (filters) => {
    filtergroup.forEach(filter => {
      if (filter?.filterlist) {
        filter.filterlist.forEach(item => {
          const checkbox = document.getElementById("filter_" + item.id);
          if (checkbox) {
            if (filters.includes(item.id)) {
              checkbox.checked = true;
              checkbox.setAttribute("checked", true);
            } else {
              checkbox.checked = false;
              checkbox.setAttribute("checked", false);
            }
          }
        });
      }
    });
  };

  /*
    Handle filter group update and URL redirection
  */
  const filtergrouplist = (id, isChecked) => {
    id = Number(id);

    setFilterGroups(currentFilterGroups => {
      const updatedFilterGroups = isChecked
        ? [...currentFilterGroups, id]
        : currentFilterGroups.filter(filterId => filterId !== id);

      redirectProduct(updatedFilterGroups);
      return updatedFilterGroups;
    });
  };

  /*
    Redirect to new URL with updated filters
  */
  const redirectProduct = (fg) => {
    const baseUrl = UrlProduct(searchParams, "filter");
    const filterParam = fg.length > 0 ? `&filters=${fg.join(',')}` : '';
    const fullUrl = `${baseUrl}${filterParam}`;
    navigate(fullUrl);
  };

  return (
    <aside className="widget widget_shop widget_filter-by-brands widget-filter">
      {filtergroup && filtergroup !== null && filtergroup !== "" && (
        <>
          {filtergroup.map((item) => (
            <>
              {item.filterlist && (
                <div className="wrapper">
                  <ul className="widget-title">
                    <li className="filtergroupli" key={item.id}>
                      <a
                        onClick={(e) => {
                          showFilters(item.filtergroupname);
                        }}
                      >
                        <div>
                          <span>{item.filtergroupname}</span>
                          <span className="pull-right openfilter " id={`up_${item.filtergroupname}`}>
                            <i className="fa fa-angle-up"></i>
                          </span>
                          <span className="pull-right openfilter fade" id={`down_${item.filtergroupname}`}>
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </div>
                      </a>

                      <div
                        id={item.filtergroupname}
                        className={
                          item && item.filterlist.length > 5 ? "openfilter filtergroup mt-40" : "openfilter  mt-40"
                        }
                      >
                        <Filters filter={item} onchangecheck={(id, flag) => filtergrouplist(id, flag)} />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ))}
        </>
      )}
    </aside>
  );
};

export default WidgetShopFilterByBrands;
