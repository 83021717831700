import React, { useEffect } from "react";

import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";

const TermsConditions = () => {
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Terms & Conditions",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <GoogleCaptcha />
      <div className="ps-page  form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>

        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">Terms & Conditions</h1>
          </div>
          <div className="ps-page__content ps-account">
            <div className="row mb-100">
              <div className="col-12 pt-20">
                <h4>Introduction</h4>
                <span className="title-font">
                  Please read these Terms and Conditions ("Terms") carefully before using the Store (the "Store")
                  operated by Plus 4 Fitness. ("us," "we," or "our"). These Terms govern your access to and use of the
                  Store. By accessing or using the Store, you agree to be bound by these Terms. If you do not agree to
                  these Terms, please do not use the Store.
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Use of the Store</h4>
                <span className="title-font">
                  <p>
                    a. You must be at least 18 years old to use the Store or have the permission of a legal guardian.{" "}
                  </p>
                  <p>b. You agree not to use the Store for any illegal or unauthorized purpose.</p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Product Listings and Pricing</h4>
                <span className="title-font">
                  <p>
                    a. We strive to provide accurate product descriptions, images, and pricing information on the Store.
                    However, we do not warrant that the information is accurate, complete, reliable, current, or
                    error-free.{" "}
                  </p>
                  <p>
                    b. The prices displayed on the Store are in indian rupee and are subject to change without notice.
                    We reserve the right to modify or discontinue any product at any time without liability.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Placing Orders</h4>
                <span className="title-font">
                  <p>
                    a. By placing an order through the Store, you are making an offer to purchase the selected products.
                    All orders are subject to acceptance by us.
                  </p>
                  <p>
                    b. We reserve the right to refuse or cancel any order for any reason, including but not limited to
                    product availability, errors in pricing or product information, or suspected fraudulent activity.
                  </p>
                  <p>c. In the event of order cancellation, we will refund any payment made for the canceled order.</p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Payment and Billing</h4>
                <span className="title-font">
                  <p>
                    a. We accept various payment methods as indicated on the Store. You agree to provide accurate and
                    complete payment information for your order
                  </p>
                  <p>
                    b. By providing your payment information, you authorize us to charge the applicable amount for your
                    order.
                  </p>
                  <p>
                    c. In the event of a payment dispute or unauthorized charges, please contact our customer support
                    team for assistance.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Shipping and Delivery</h4>
                <span className="title-font">
                  <p>
                    a. We will make reasonable efforts to ship your order within the estimated timeframe provided.
                    However, we do not guarantee delivery dates and are not responsible for any delays or damages caused
                    by the shipping carrier
                  </p>
                  <p>b. Shipping costs, if applicable, will be added to your order and displayed at checkout.</p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Returns and Refunds</h4>
                <span className="title-font">
                  <p>
                    a. Our return and refund policy is outlined on the Store's website. Please review the policy before
                    making a purchase.
                  </p>
                  <p>
                    b. We reserve the right to refuse returns or refunds if the products are damaged due to misuse or if
                    they are not returned in their original condition and packaging.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Limitation of Liability</h4>
                <span className="title-font">
                  <p>
                    To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental,
                    consequential, or special damages arising out of or in connection with your use of the Store or any
                    products purchased through the Store.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Indemnification</h4>
                <span className="title-font">
                  <p>
                    You agree to indemnify, defend, and hold harmless Company and its affiliates, officers, directors,
                    employees, and agents from and against any and all claims, liabilities, expenses, damages, and
                    costs, including reasonable attorney's fees, arising out of or in connection with your use of the
                    Store or any violation of these Terms.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Governing Law and Jurisdiction</h4>
                <span className="title-font">
                  <p>
                    These Terms shall be governed by and construed in accordance with the laws of disctrict court of
                    valsad. Any legal action or proceeding arising out of or relating to these Terms or your use of the
                    Store shall be exclusively brought in the courts located in VALSAD, Gujarat.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Severability</h4>
                <span className="title-font">
                  <p>
                    If any provision of these Terms is found to be unenforceable or invalid, the remaining provisions
                    shall remain in full force and effect
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Changes to the Terms</h4>
                <span className="title-font">
                  <p>
                    We reserve the right to modify or update these Terms at any time without prior notice. Any changes
                    will be effective immediately upon posting on the Store. Your continued use of the Store after the
                    posting of changes constitutes your acceptance of such changes.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Contact Us</h4>
                <span className="title-font">
                  <p>
                    If you have any questions, concerns, or requests regarding these Terms, please contact us at
                    <b> plus4fitness@gmail.com</b>.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <span className="title-font">
                  <p>
                    By using the Store, you acknowledge that you have read and understood these Terms and agree to be
                    bound by them
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default TermsConditions;
