import { Button, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { UrlProduct } from "../../../../pages/product/LoadProduct";

const ModuleShopSortBy = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let sortby = searchParams.get("sortby");
  if (!sortby) {
    sortby = "Latest";
  }
  const [sortbyproduct, setSortby] = useState(sortby);

  /*
    To sort item json
  */
  const sortByItems = [
    {
      id: 1,
      text: "Latest",
      query_text: "latest",
    },
    {
      id: 2,
      text: "Price: Low - High",
      query_text: "low-high",
    },
    {
      id: 3,
      text: "Price: High - Low",
      query_text: "high-low",
    },
  ];

  /*
    To redirect url to shop
  */
  const setProductShort = (shortitem, text) => {
    setSortby(text);
    redirectProduct(shortitem);
  };

  /*
    To redirect url
  */
  const redirectProduct = (shortitem) => {
    navigate(UrlProduct(searchParams, "sortby") + "&sortby=" + shortitem);
  };

  /*
    To view
  */
  const viewItems = sortByItems.map((item) => (
    <Menu.Item key={item.id}>
      <a onClick={(e) => setProductShort(item.query_text, item.text)}>{item.text}</a>
    </Menu.Item>
  ));
  const view = <Menu>{viewItems}</Menu>;

  return (
    <div className="ps-shop__sortby">
      <span className="action-label">Sorting</span>
      <Dropdown overlay={view} placement="bottomLeft" className="ps-dropdown-toggle">
        <Button className="pt-0">
          <span className="dark-blue mr-10"> {sortbyproduct} </span>
          <span style={{ width: "10px" }}>
            <i className="icon-chevron-down"></i>
          </span>
        </Button>
      </Dropdown>
    </div>
  );
};

export default ModuleShopSortBy;
