import React from "react";

import shopconfig from "../../../../config/shopconfig.json";

const ModuleWhyUsOne = () => {
  return (
    <div className="aboutus-section--two-1">
      <div className="whyus-one-container">
        <div className="row m-0">
          <div className="col-12 col-md-12 p-0">
            <div className="text-section row m-0">
              <div className="col-12 col-md-6 info-section">
                <div className="row m-0">
                  <div className="col-3 p-0 d-flex justify-content-center align-items-center">
                    <div className="icon-container">
                      <img src="/static/img/static_image/payment.png" />
                    </div>
                  </div>
                  <div className="col-7 p-0">
                    <div className="text-container">
                      <h3>Secure Payment</h3>
                      <p>Partnered with India's most trustable payment solutions</p>
                    </div>
                  </div>
                </div>

                <div className="row m-0">
                  <div className="col-3 p-0 d-flex justify-content-center align-items-center">
                    <div className="icon-container">
                      <img src="/static/img/static_image/delivery.png" />
                    </div>
                  </div>
                  <div className="col-7 p-0">
                    <div className="text-container">
                      <h3>Nationwide Delivery</h3>
                      <p>Over 10,000 pincodes serviceable in India</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 info-section">
                <div className="row m-0">
                  <div className="col-3 p-0 d-flex justify-content-center align-items-center">
                    <div className="icon-container">
                      <img src="/static/img/static_image/quality.png" />
                    </div>
                  </div>
                  <div className="col-7 p-0">
                    <div className="text-container">
                      <h3>Assured Quality</h3>
                      <p>Provide customer best quality assurance with best services</p>
                    </div>
                  </div>
                </div>

                <div className="row m-0">
                  <div className="col-3 p-0 d-flex justify-content-center align-items-center">
                    <div className="icon-container">
                      <img src="/static/img/static_image/help.png" />
                    </div>
                  </div>
                  <div className="col-7 p-0">
                    <div className="text-container">
                      <h3>365 Help Desk</h3>
                      <p>
                        <i class="fi fi-rr-phone-call d-flex align-items-center"></i>
                        <span>{shopconfig.shop_contactno}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleWhyUsOne;
