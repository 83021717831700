import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import Select from "react-select";

const SelectBox = ({
  label,
  name,
  options,
  placeholder,
  setSelectedCountry,
  initialValues,
  value,
  getState,
  setCountryname,
  setStatename,
  ref,
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  return (
    <React.Fragment>
      <div className="ps-form__group form-group">
        {label ? <label className="ps-form__label">{label}</label> : ""}
        <div className="input-group">
          <Select
            name={name}
            options={options}
            className="form-control ps-form__input combo-select"
            placeholder={placeholder}
            ref={ref}
            onChange={(value) => {
              setSelectedCountry(value);
              if (name === "country") {
                values.country = value.value;
                initialValues.country = value.value;
                setFieldValue("state", "");
              } else if (name === "wanumbercountry") {
                values.wanumbercountry = value.value;
                initialValues.wanumbercountry = value.value;
              } else if (name === "state") {
                values.state = value.value;
                initialValues.state = value.value;
              } else if (name === "addressphonecountry") {
                values.addressphonecountry = value.value;
                initialValues.addressphonecountry = value.value;
              } else if (name === "billingcountry") {
                values.billingcountry = value.value;
                initialValues.billingcountry = value.value;
                setFieldValue("billingstate", "");
              } else if (name === "billingstate") {
                values.billingstate = value.value;
                initialValues.billingstate = value.value;
              } else if (name === "billingmobilecountry") {
                values.billingmobilecountry = value.value;
                initialValues.billingmobilecountry = value.value;
              } else {
                values.phonecountry = value.value;
                initialValues.phonecountry = value.value;
              }

              if (getState) {
                getState(value.value);
              }
              if (setCountryname) {
                setCountryname(value.label);
              }
              if (setStatename) {
                setStatename(value.label);
              }
              setFieldValue(value);
            }}
            value={value}
          />
        </div>

        <ErrorMessage name={name} component="span" className="color-red" />
      </div>
    </React.Fragment>
  );
};
export default SelectBox;
