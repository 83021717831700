import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";

import config from "../../config/config.json";
import { currencyPrice } from "../../hooks/currencyCalculation";
import { calculateProductPriceOffer } from "../../utilities/product-helper";
import ProductRepository from "../../api/ProductRepository";
import useProductHook from "../../hooks/useProductHook";
import ProductVariantModal from "../shared/modals/ProductVariantModal";

const ProductLayoutTwoOptionTwo = ({ product, currencyrate, ecomerce }) => {
  const {
    quantity,
    isVarientobject,
    show,
    productItem,
    variantArray,
    groupedVariantArray,
    handleClose,
    handleAddItemToCart,
    handleIncreaseItemQty,
    handleDecreaseItemQty,
    addToCart,
    setSelectedVariant,
    handleAddItemToWishlist,
    handleRemoveItem,
    getImageURL,
  } = useProductHook(product, currencyrate, ecomerce);
  const navigate = useNavigate();

  const [variantList, setVariantList] = useState();
  const [vjson, setVjson] = useState([]);
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [selectedSingleVariant, setSelectedSingleVariant] = useState("");

  const imageUrls = getImageURL(product);
  const displayLimit = 5;
  const singleVariantKey = keys.length > 0 ? keys[0] : null;
  const variantValues = [...new Set(vjson.map((item) => item[singleVariantKey]).flat())];

  const displayedVariants = showMore ? variantValues : variantValues.slice(0, displayLimit);
  const remainingCount = variantValues.length - displayedVariants.length;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const handleChange = (event) => {
    setSelectedSingleVariant(event.target.value);
  };

  //GET VARIANTS
  useEffect(() => {
    getVariants();
  }, []);

  const getVariants = async () => {
    const variants = await ProductRepository.getProductVariant(product.uniqueid);
    setVariantList(variants);
  };

  useEffect(() => {
    const vjson = variantList
      ? variantList.map((item) => {
          if (typeof item.variantjson === "string") {
            try {
              return JSON.parse(item.variantjson);
            } catch (error) {
              console.error("Invalid JSON string:", item.variantjson);
              return {};
            }
          } else {
            return item.variantjson;
          }
        })
      : [];

    if (vjson.length > 0) {
      const keys = Object.keys(vjson[0]);
      setKeys(keys);
    }

    setVjson(vjson);
    setLoading(false);
  }, [variantList]);

  if (loading) {
    return <div></div>;
  }

  return (
    <>
      <div className="ptwo-optiontwo">
        <div className="ptwo-ofour-product-card">
          <Link to={`/product/${product.uniqueid}`}>
            <div className="row p-0 m-0 d-flex flex-column">
              <div className="col-12 col-md-12 p-0">
                <div className="ptwo-ofour-img-container">
                  <LazyLoad>
                    <img src={imageUrls[0]} className="ptwo-ofour-productimage" />
                  </LazyLoad>
                  {/* <Slider {...settings}>
                    {Array.isArray(imageUrls) &&
                      imageUrls.map((image, index) => (
                        <div key={index} className="slider-image-container">
                          <img src={image} alt={`Slide ${index}`} className="ptwo-ofour-productimage" />
                        </div>
                      ))}
                  </Slider> */}
                </div>
                {/* <div className="line-container">
                  <div className="ptwo-ofour-line">
                    <div className="line-half"></div>
                  </div>
                </div> */}
                <div className="ptwo-ofour-info-container">
                  <div className="title-wishlist-container">
                    <h4 className="ptwo-ofour-producttitle">{product.productname}</h4>
                    {product.alreadyinwishlist === null || product.alreadyinwishlist === "0" ? (
                      <button className="pfour-wishlist-btn p-0" onClick={(e) => handleAddItemToWishlist(e)}>
                        <i className="fa fa-heart-o"></i>
                      </button>
                    ) : (
                      <button
                        className="pfour-wishlist-btn-active p-0 ml-2"
                        onClick={(e) => handleRemoveItem(e, product.wishlistid)}
                      >
                        <i className="fa fa-heart"></i>
                      </button>
                    )}
                  </div>
                  <div className="pfour-price-contianer" id={`price_${product.id}`}>
                    {product.offerprice && Number(product.offerprice) > 0 ? (
                      <>
                        <span className="pfour-product-price">{currencyPrice(product.offerprice, currencyrate)}</span>
                        <span className="pfour-actual-price ml-2"> {currencyPrice(product.price, currencyrate)}</span>
                        <span className="pfour-offer-price ml-3">({calculateProductPriceOffer(product)} %)</span>
                      </>
                    ) : (
                      <span className="pfour-product-price">{currencyPrice(product.price, currencyrate)}</span>
                    )}
                  </div>
                  {singleVariantKey ? (
                    <div className="pfour-variant-container">
                      {displayedVariants
                        .slice(0, variantValues.some((value) => value.length > 13) ? 1 : displayLimit)
                        .map((value, index) => (
                          <label
                            key={index}
                            className={`badge-variant pfour-variant mr-2 ${
                              selectedSingleVariant === value ? "selected" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="variant"
                              value={value}
                              checked={selectedSingleVariant === value}
                              onChange={handleChange}
                              className="d-none"
                            />
                            {value}
                          </label>
                        ))}
                      {remainingCount > 0 && !showMore && (
                        <button onClick={() => navigate(`/product/${product.uniqueid}`)} className="show-more-btn">
                          +{remainingCount} more
                        </button>
                      )}
                    </div>
                  ) : (
                    <p className="no-veriant-height"></p>
                  )}
                  <div className="tag-quantity-container">
                    <span
                      className={`badge text-capitalize ${product.stock > 0 ? "pfour-instock" : "pfour-outofstock"}`}
                    >
                      {product.stock > 0 ? "In Stock" : "Out of Stock"}
                    </span>
                    <div className="pfour-qty-wishlist-container">
                      {config.isdispalayquantity === 0 ? (
                        <>
                          <div className="pfour-qty-btn-container">
                            <button className="pfour-qty-btn" onClick={(e) => handleDecreaseItemQty(e)}>
                              -
                            </button>
                            <button className="pfour-qty ml-2 disabled">{quantity}</button>
                            <button className="pfour-qty-btn ml-2" onClick={(e) => handleIncreaseItemQty(e)}>
                              +
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {product.stock > 0 && product.price > 0 ? (
                    <button className="w-100 ptwo-otwo-addtocart mb-20" onClick={(e) => handleAddItemToCart(e)}>
                      Add To Cart
                    </button>
                  ) : (
                    <button className="w-100 ptwo-otwo-addtocart mb-20">View Details</button>
                  )}
                </div>
              </div>
            </div>
          </Link>
          <ProductVariantModal
            show={show}
            handleClose={handleClose}
            productItem={productItem}
            isVarientobject={isVarientobject}
            currencyrate={currencyrate}
            variantArray={variantArray}
            groupedVariantArray={groupedVariantArray}
            setSelectedVariant={setSelectedVariant}
            product={product}
            addToCart={addToCart}
          />
        </div>
      </div>
    </>
  );
};

export default connect((state) => state)(ProductLayoutTwoOptionTwo);
