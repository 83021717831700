import React, { useState } from 'react';

import ModuleDetailProductMeta from '../../modules/ModuleDetailProductMeta';

const ModuleProductDetail = ({ product }) => {
  const [expandedSections, setExpandedSections] = useState({
    description: true,
    specification: false,
  });

  const toggleContent = (id) => {
    setExpandedSections((prevSections) => ({
      ...prevSections,
      [id]: !prevSections[id],
    }));
  };

  return (
    <>
      <div className='product-detail-container'>
        <h3>Prdoduct Detail</h3>
        <li className='d-flex justify-content-between' onClick={() => toggleContent('description')}>
          <p>Description</p>
          <i className={`icon-plus ${expandedSections.description ? 'icon-minus' : ''}`}></i>
        </li>
        <div className={`content ${expandedSections.description ? 'expanded' : 'collapsed'}`}>
          <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
        </div>
        {product && product.productmeta && product.productmeta.length > 0 &&
          <>
            <li className='d-flex justify-content-between' onClick={() => toggleContent('specification')}>
              <p>Specification</p>
              <i className={`icon-plus ${expandedSections.specification ? 'icon-minus' : ''}`}></i>
            </li>
            <div className={`content d-flex ${expandedSections.specification ? 'expanded' : 'collapsed'}`}>
              <div className='product-meta-container'>
                <ModuleDetailProductMeta product={product} />
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default ModuleProductDetail