import React, { useEffect, useRef, useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import Slider from "react-slick";

// import Lightbox from "react-image-lightbox";
import NextArrow from "../../../../components/elements/carousel/NextArrow";
import PrevArrow from "../../../../components/elements/carousel/PrevArrow";
import config from "../../../../config/config.json";

/*
  To variant setting
*/
const variantSetting = {
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 6,
        dots: false,
        arrows: false,
        vertical: false,
        infinite: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        dots: false,
        arrows: false,
        vertical: false,
        infinite: false,
      },
    },
  ],
};

/*
  To gallery setting
*/
const gallerySetting = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const ModuleDetailThumbnail = ({ product, vertical = true }) => {
  const galleryCarousel = useRef(null);
  const variantCarousel = useRef(null);
  const [gallery, setGallery] = useState(null);
  const [variant, setVariant] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [productImages, setProductImages] = useState([]);
  const [isimagesView, setimagesView] = useState([]);
  const [isgalleryImagesView, setGalleryImagesView] = useState([]);

  let imagepath = config.apiUrl + config.image_path;

  /*
    To open lightbox
  */
  const handleOpenLightbox = (e, imageIndex) => {
    e.preventDefault();
    setPhotoIndex(imageIndex);
    setIsOpen(true);
  };
  if (config.isslider !== "false") {
    vertical = true;
  }
  useEffect(() => {
    let images = [];

    /*
      To manage image gallary 
    */
    if (
      product &&
      product.productvarientimagelist &&
      product.productvarientimagelist !== null &&
      product.productvarientimagelist.length > 0
    ) {
      product.productvarientimagelist.map((item) => {
        if (item.imagename !== null && item.imagename !== "") {
          images.push(`${imagepath}${item.imagename}`);
        } else {
          images.push(`/static/img/noproduct-images.png`);
        }
      });
    } else {
      if (product.imagename !== null && product.imagename !== "") {
        images.push(`${imagepath}${product.imagename}`);
      } else {
        images.push(`/static/img/noproduct-images.png`);
      }
      if (product && product.productimagelist !== null && product.productimagelist.length > 0) {
        product.productimagelist.map((item) => {
          if (item.imagename !== null && item.imagename !== "") {
            images.push(`${imagepath}${item.imagename}`);
          } else {
            images.push(`/static/img/noproduct-images.png`);
          }
        });
      }
    }

    if (images.length > 0) {
      setProductImages(images);
    }
    setGallery(galleryCarousel.current);
    setVariant(variantCarousel.current);
    setImageList(images);
  }, [product.productimagelist, product.productvarientimagelist]);

  /*
    To set image list 
  */
  let lightboxView;
  const setImageList = (images) => {
    setimagesView(
      <div className={config.isslider === "false" ? "item" : "item sliderimage"}>
        <img src="/static/img/noproduct-images.png" alt="noproduct" />
      </div>
    );
    setGalleryImagesView(
      <div className="item">
        <a>
          <img src="/static/img/noproduct-images.png" alt="noproduct" />
        </a>
      </div>
    );
    if (images.length > 0) {
      setimagesView(
        images.map((item, index) => (
          <div className={config.isslider === "false" ? "item" : "item sliderimage"} key={index}>
            <img src={item} />
          </div>
        ))
      );
      setGalleryImagesView(
        images.map((item, index) => (
          <div className="item " key={index}>
            <a href="#" className="b-1" onClick={(e) => handleOpenLightbox(e, index)}>
              <InnerImageZoom src={item} hideHint={true} className={"b-1"} />
            </a>
          </div>
        ))
      );
    }
  };

  return (
    <div
      className={config.isslider === "false" ? "" : "ps-product__thumbnail"}
      data-vertical={vertical ? "true" : "false"}
    >
      {vertical ? (
        <>
          <div className="isslider">
            <div className="slider-left desk-slider slider-scroll">
              {config.isslider === "false" ? (
                <>
                  {vertical ? (
                    <Slider
                      {...variantSetting}
                      asNavFor={gallery}
                      ref={(slider) => (variantCarousel.current = slider)}
                      swipeToSlide={true}
                      centered={true}
                      arrows={true}
                      slidesToShow={6}
                      vertical={true}
                      infinite={true}
                      focusOnSelect={true}
                      className="ps-product__variants ps-carousel"
                      prevArrow={<button><i className="icon-chevron-up"></i></button>}
                      nextArrow={<button><i className="icon-chevron-down"></i></button>}
                    >
                      {isimagesView}
                    </Slider>
                  ) : (
                    productImages.map((item, index) => (
                      <div className="col-6 product-detail-image">
                        <div className="item product--image-detail" key={index}>
                          <InnerImageZoom src={item} hideHint={true} className={"b-1"} />
                        </div>
                      </div>
                    ))
                  )}
                </>
              ) : (
                <Slider
                  {...variantSetting}
                  asNavFor={gallery}
                  ref={(slider) => (variantCarousel.current = slider)}
                  swipeToSlide={true}
                  centered={true}
                  arrows={true}
                  slidesToShow={6}
                  vertical={true}
                  infinite={true}
                  focusOnSelect={true}
                  className="ps-product__variants ps-carousel"
                  prevArrow={<button><i className="icon-chevron-up"></i></button>}
                  nextArrow={<button><i className="icon-chevron-down"></i></button>}
                >
                  {isimagesView}
                </Slider>
              )}
            </div>
            <figure className={config.isslider === "false" ? "" : ""}>
              <div className="ps-wrapper">
                <Slider
                  {...gallerySetting}
                  ref={(slider) => (galleryCarousel.current = slider)}
                  asNavFor={variant}
                  className="ps-product__gallery ps-carousel inside"
                >
                  {isgalleryImagesView}
                </Slider>
                {lightboxView}
              </div>
              <div>
                <img src="/static/img/veg.png" alt="" className="vegimg" />
              </div>
            </figure>
          </div>

          <div className="mobile-slider">
            <div className="row ml-0 mr-0">
              {config.isslider === "false" ? (
                <>
                  {vertical ? (
                    <Slider
                      asNavFor={gallery}
                      ref={(slider) => (variantCarousel.current = slider)}
                      swipeToSlide={true}
                      centered={false}
                      arrows={false}
                      slidesToShow={3}
                      vertical={false}
                      infinite={false}
                      focusOnSelect={true}
                      {...variantSetting}
                      className="ps-product__variants"
                    >
                      {isimagesView}
                    </Slider>
                  ) : (
                    productImages.map((item, index) => (
                      <div className="col-6 product-detail-image">
                        <div className="item product--image-detail" key={index}>
                          <InnerImageZoom src={item} hideHint={true} className={"b-1"} />
                        </div>
                      </div>
                    ))
                  )}
                </>
              ) : (
                <Slider
                  asNavFor={gallery}
                  ref={(slider) => (variantCarousel.current = slider)}
                  swipeToSlide={true}
                  centered={false}
                  arrows={false}
                  slidesToShow={3}
                  vertical={false}
                  infinite={false}
                  focusOnSelect={true}
                  {...variantSetting}
                  className="ps-product__variants"
                >
                  {isimagesView}
                </Slider>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="row ml-0 mr-0">
          {" "}
          {config.isslider === "false" ? (
            <>
              {vertical ? (
                <Slider
                  asNavFor={gallery}
                  ref={(slider) => (variantCarousel.current = slider)}
                  swipeToSlide={true}
                  centered={false}
                  arrows={false}
                  slidesToShow={3}
                  vertical={false}
                  infinite={false}
                  focusOnSelect={true}
                  {...variantSetting}
                  className="ps-product__variants"
                >
                  {isimagesView}
                </Slider>
              ) : (
                productImages.map((item, index) => (
                  <div className="col-6 product-detail-image">
                    <div className="item product--image-detail" key={index}>
                      <InnerImageZoom src={item} hideHint={true} className={"b-1"} />
                    </div>
                  </div>
                ))
              )}
            </>
          ) : (
            <Slider
              asNavFor={gallery}
              ref={(slider) => (variantCarousel.current = slider)}
              swipeToSlide={true}
              centered={false}
              arrows={false}
              slidesToShow={3}
              vertical={true}
              infinite={false}
              focusOnSelect={true}
              {...variantSetting}
              className="ps-product__variants"
            >
              {isimagesView}
            </Slider>
          )}
        </div>
      )}
    </div>
  );
};

export default ModuleDetailThumbnail;
