import axios from "axios";

import config from "../config/config.json";

const headers = {
  Accept: "*/*",
  Authorization: "Bearer " + localStorage.getItem("authToken"),
};

/*
  To add to cart
*/
const addToCart = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/cart/add";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To remove cart by id
*/
const removeCart = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/cart/remove";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To get cart list
*/
const getCartlist = async () => {
  const url = config.apiUrl + config.apiurlname + "/cart";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To update cart
*/
const updateCart = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/cart/update";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To get wishlist and cart count
*/
const getWishlistAndCartCount = async () => {
  const url = config.apiUrl + config.apiurlname + "/commoncount";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To remove all cart
*/
const removeAllCart = async () => {
  const url = config.apiUrl + config.apiurlname + "/cart/removeall";
  const respo = await axios
    .post(url, "", { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To shipping calculation
*/
const shippingCalculation = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/orders/calculateshippingcharge";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To save order
*/
const saveorder = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/orders/save";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To update order
*/
const orderupdate = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/orders/updatepaymentstatus";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To check product stock
*/
const checkStock = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/orders/checkproductquantity";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To payment gateway list
*/
const getPaymentGatewayList = async () => {
  const url = config.apiUrl + config.apiurlname + "/paymentgateway";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To get active payment gateway
*/
const getActivePaymentGateWay = async () => {
  const url = config.apiUrl + config.apiurlname + "/paymentgateway/active";
  const res = await axios.get(url, { headers }).then((response) => {
    return response;
  }).catch((error) => {
    if (error.response) {
      return error.response;
    }
  });
  return res;
}

/*
  To special price discount
*/
const specialPriceDiscount = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/cart/specialpricediscount";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

export default {
  addToCart,
  removeCart,
  getCartlist,
  updateCart,
  getWishlistAndCartCount,
  removeAllCart,
  shippingCalculation,
  saveorder,
  orderupdate,
  checkStock,
  getPaymentGatewayList,
  getActivePaymentGateWay,
  specialPriceDiscount,
};
