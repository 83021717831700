import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import ModuleBlogCategory from "./modules/ModuleBlogCategory";
import ModuleBlogTags from "./modules/ModuleBlogTags";
import ModuleBlogDetail from "./modules/ModuleBlogDetail";
import useBlog from "../../hooks/useBlog";

const BLogDetailPage = () => {
  const location = useLocation();
  const { id } = useParams();

  const { loading, blogdetail, getBlogDetailById } = useBlog();
  const { blogcategorylist, getBlogCategotyList } = useBlog();
  const { blogtaglist, getBlogTagList } = useBlog();

  const [flag, setFlag] = useState(false);

  const blogtittle = blogdetail.map((item) => item.title);

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "Blog",
      url: "/blogs",
    },
    {
      id: 3,
      text: blogtittle,
    },
  ];

  /*
    To load data at the time of page initilization
  */
  useEffect(() => {
    setFlag(false);
    getBlogDetailById(id);
    getBlogCategotyList();
    getBlogTagList();
    window.scrollTo(0, 0);
    setFlag(true);
  }, [id]);

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="ps-page ps-page--blog">
          <div className="container">
            <div className="ps-page__header pb-0">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="blog-detail-page">
                <div className="row mt-10">
                  <div className="col-md-9 p-0">
                    <ModuleBlogDetail blogdetail={blogdetail} />
                  </div>
                  <div className="col-md-3">
                    <ModuleBlogCategory blogcategorylist={blogcategorylist} />
                    <ModuleBlogTags blogtaglist={blogtaglist} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default connect((state) => state)(BLogDetailPage);
