import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import LocalstorageParam from "../common/LocalstorageParam";

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "My Account",
    url: "/profile",
  },
  {
    id: 3,
    text: "Help",
  },
];

const Help = () => {
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  useEffect(() => {
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
      }, 500);
    } else {
      navigate("/login");
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Help"} />
                </div>
                <div className="ps-layout__right">
                  <div className="ps-page__content">
                    <div className="row">
                      <div className="col-12 col-md-12 ps-form--review">
                        <h2 className="ps-form__title mb-20">Help</h2>

                        <div className="row">
                          <div className="col-12 col-md-4">
                            <div className="help-card box-rounded width-100">
                              <div className="row">
                                <div className="col-3 align-self-center">
                                  <i className="fi fi-rr-comment-question"></i>
                                </div>
                                <div className="col-9 align-self-center">
                                  <a href="/faqs">
                                    <span>FAQ's</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div className="help-card box-rounded width-100">
                              <div className="row">
                                <div className="col-3 align-self-center">
                                  <i className="fi fi-rr-headset"></i>
                                </div>
                                <div className="col-9 align-self-center">
                                  <a href="/contactus">
                                    <span>Contact Us</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div className="help-card box-rounded width-100">
                              <div className="row">
                                <div className="col-3 align-self-center">
                                  <i className="fi fi-rr-incognito"></i>
                                </div>
                                <div className="col-9 align-self-center">
                                  <a href="/privacypolicy">
                                    <span>Privacy Policy</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-4">
                            <div className="help-card box-rounded width-100">
                              <div className="row">
                                <div className="col-3 align-self-center">
                                  <i className="fi fi-rr-undo"></i>
                                </div>
                                <div className="col-9 align-self-center">
                                  <a href="/returnpolicy">
                                    <span>Return Policy</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div className="help-card box-rounded width-100">
                              <div className="row">
                                <div className="col-3 align-self-center">
                                  <i className="fi fi-rr-document-signed"></i>
                                </div>
                                <div className="col-9 align-self-center">
                                  <a href="/termsconditions">
                                    <span>Terms & Conditions</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Help;
