import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import user from "../../api/user";
import InputField from "../../components/elements/form/InputField";
import SelectBox from "../../components/elements/form/SelectBox";

const BillingAddress = ({ countrylist, searchparam, localparam, sameasshipping }) => {
  const navigate = useNavigate();
  const ref = useRef(null);

  const [selectedcountry, setSelectedcountry] = useState();
  const [selectedstate, setSelectedstate] = useState();
  const [selectedcountrycode, setSelectedCountryCode] = useState();
  const [countryname, setCountryname] = useState([]);
  const [statename, setStatename] = useState([]);
  const [state, setState] = useState([]);
  const [iscountrylist, setIsCountryList] = useState(countrylist);
  const [CountryArray, setCountryArray] = useState([]);
  const [StateArray, setStateArray] = useState([]);
  const [CountryCodeArray, setCountryCodeArray] = useState([]);
  const [localparams, setLocalparams] = useState();

  useEffect(() => {
    const billingData = localStorage.getItem("billingdata");
    if (billingData) {
      try {
        const parsedData = JSON.parse(billingData);
        setLocalparams(parsedData);
      } catch (error) {
        console.error("Error parsing billing data:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (countrylist.length > 0) {
      setIsCountryList(countrylist);
      setCountryListInArray();
    }
  }, [countrylist]);

  useEffect(() => {
    if (localparam) {
      setBillingForm(localparam);
    }
  }, [localparam, iscountrylist]);

  const setCountryListInArray = () => {
    if (countrylist && countrylist.length > 0) {
      const countryarray = countrylist.map(item => ({
        value: item.id,
        label: item.name
      }));
      const countrycodearray = countrylist.map(item => ({
        value: item.extension,
        label: item.extension
      }));
      setCountryArray(countryarray);
      setCountryCodeArray(countrycodearray);
    }
  };

  /*
    To redirect to order summary
  */
  const redirectToOrderSummary = () => {
    navigate("/ordersummary?coupon=" + searchparam.urlcoupon + "&rewardpoints=" + searchparam.urlpoints);
  };

  if (!localparam) {
    return <div>No address found</div>;
  }

  const trimFields = (values) => {
    return {
      ...values,
      billingcity: values.billingcity.trim(),
      billingaddress: values.billingaddress.trim(),
      billingcompanyname: values.billingcompanyname.trim(),
    };
  };

  const handleSubmit = (values) => {
    const trimmedValues = trimFields(values);
    setBillingdata(trimmedValues);
    redirectToOrderSummary();
  };

  /*
    To set billing address data on local storage
  */
  const setBillingdata = (billingdata) => {
    if (billingdata !== null && billingdata !== "") {
      billingdata.billingcountryname = countryname;
      billingdata.billingstatename = statename;
      if (!document.getElementById("sameshippingaddress").checked) {
        var object = new Object();
        object["billingdata"] = billingdata;
        localStorage.setItem("billingdata", JSON.stringify(billingdata));
      }
      if (sameasshipping === "1" && billingdata.billinggst) {
        localparam.billinggst = billingdata.billinggst;
        localStorage.setItem("billingdata", JSON.stringify(localparam));
      }
    }
  };

  /*
    To form validation using yup
  */
  const validationSchema = Yup.object().shape({
    billingname: Yup.string()
      .required("Please enter value for Fullname")
      .matches(/^[^\s][a-zA-Z '.-]*$/, "Please enter valid Fullname"),
    billingmobile: Yup.string()
      .matches(/^[0-9]+$/, "Please enter valid mobile no")
      .required("Please enter value for Mobile No")
      .min(10, "Please enter valid Mobile no"),
    billingmobilecountry: Yup.string().required("Please enter value for Country Code"),
    billingstate: Yup.string().required("Please enter value for State"),
    billingcity: Yup.string()
      .matches(/[^\s*]/g, 'White Space is not allowed')
      .required("Please enter value for City"),
    billingpincode: Yup.string()
      .matches(/^[0-9]+$/, "Please enter valid Pincode")
      .min(6, "Please enter valid Pincode")
      .required("Please enter value for Pincode"),
    billingaddress: Yup.string()
      .matches(/[^\s*]/g, 'White Space is not allowed')
      .required("Please enter value for Address"),
    billingcountry: Yup.string().required("Please enter value for Country "),
  });

  /*
    To set in billing form
  */
  const setBillingForm = (params) => {
    if (params) {
      if (params.billingcountry) {
        var list = CountryArray.find((item) => Number(item.value) === Number(params.billingcountry));
        if (list) {
          setSelectedcountry(list);
        }
      }
      if (params.billingmobilecountry) {
        var list = CountryCodeArray.find((item) => Number(item.value) === Number(params.billingmobilecountry));
        if (list) {
          setSelectedCountryCode(list);
        }
      }
      setCountryname(params.billingcountryname);
      setStatename(params.billingstatename);
      getState(params.billingcountry, params);
    }
  };

  /*
    To get state list by countryid
  */
  const getState = async (countryid, billing) => {
    if (countryid && countryid !== null && countryid !== "") {
      setSelectedstate("");
      setState([]);
      const res = await user.getState(countryid);
      if (res.data.status === 200) {
        setSelectedstate([]);
        const statearray = res.data.payload.states.map(item => ({ value: item.id, label: item.name }));
        setStateArray(statearray);
        if (billing) {
          const selectedState = statearray.find(item => Number(item.value) === Number(billing.billingstate));
          setSelectedstate(selectedState || null);
        }
      }
    }
  };

  /*
     To initial values json
   */
  const initialValues = localparam || {
    billingname: '',
    billingmobile: '',
    billingmobilecountry: '',
    billingstate: '',
    billingcity: '',
    billingpincode: '',
    billingaddress: '',
    billingcountry: '',
    billingcompanyname: '',
    billinggst: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ setFieldValue }) => (
        <Form id="billingForm">
          <div className="row mt-20">
            <div className="col-12 col-md-6">
              <InputField
                id="billingname"
                label="Full Name *"
                name="billingname"
                type="text"
                placeholder="Full Name"
                maxLength="50"
              />
            </div>
            <div className="col-12 col-md-6">
              <SelectBox
                id="billingcountry"
                label="Country *"
                name="billingcountry"
                options={CountryArray}
                placeholder="Country"
                setSelectedCountry={(option) => {
                  setSelectedcountry(option);
                  setFieldValue('billingcountry', option.value);
                  setFieldValue('billingstate', '');
                  setSelectedstate(null);
                  getState(option.value);
                  setCountryname(option.label);
                }}
                value={selectedcountry}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <SelectBox
                id="billingstate"
                label="State *"
                name="billingstate"
                options={StateArray}
                placeholder="State"
                setSelectedCountry={(option) => {
                  setSelectedstate(option);
                  setFieldValue('billingstate', option.value);
                  setStatename(option.label);
                }}
                value={selectedstate}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                id="billingcity"
                label="City *"
                name="billingcity"
                type="text"
                placeholder="City"
                maxLength="50"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputField
                id="billingpincode"
                label="Pincode *"
                name="billingpincode"
                type="text"
                placeholder="Pincode"
                maxLength="6"
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                id="billingcompanyname"
                label="Company Name"
                name="billingcompanyname"
                type="text"
                placeholder="Company Name"
                maxLength="50"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <InputField
                id="billingaddress"
                label="Address *"
                name="billingaddress"
                placeholder="Address"
                maxLength="500"
                component="textarea"
                rows="3"
                classname="ps-form__textarea"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-12 col-md-5">
                  <SelectBox
                    id="billingmobilecountry"
                    label="Country Code *"
                    name="billingmobilecountry"
                    options={CountryCodeArray}
                    placeholder="Country Code"
                    setSelectedCountry={(option) => {
                      setSelectedCountryCode(option);
                      setFieldValue('billingmobilecountry', option.value);
                    }}
                    value={selectedcountrycode}
                  />
                </div>
                <div className="col-12 col-md-7">
                  <InputField
                    id="billingmobile"
                    label="Mobile No *"
                    name="billingmobile"
                    type="text"
                    placeholder="Mobile No"
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <InputField
                id="billinggst"
                label="GST"
                name="billinggst"
                type="text"
                placeholder="GST"
                maxLength="50"
              />
            </div>
          </div>
          <span className="color-red hide pl-15 pt-10" id="errormsg"></span>
          <button className="ps-btn ps-btn--warning width-100 hidden" id="savebillingbtn" ref={ref}>
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default connect((state) => state)(BillingAddress);
