import { React } from "react";

const ShippingBillingAddress = ({ orderdetail }) => {
  /*
    To order shipping and billing address views
  */
  const items = orderdetail.map((item) => (
    <>
      <div className="col-12 pt-20 box-rounded width-100 mt-20">
        <div className="ps-cart__content">
          <div className="ps-cart__items order-summary bn">
            <div className="row">
              <div className="col-12  mb-10">
                <h3 className="ps-form__title">Shipping Address</h3>
              </div>
              <div className="col-12 order-pading">
                <span className="ps-text--order">
                  <i className="fa fa-user color-blue"></i>
                  <span className="pl-10 word-break-all color-blue">{item.shippingname}</span>
                </span>
              </div>
              <div className="col-12 order-pading">
                <span className="ps-text--order">
                  <i className="fa fa-phone color-blue"></i>
                  <span className="pl-10 title-font">
                    {item.shippingmobilecountry}
                    {item.shippingmobile}
                  </span>
                </span>
              </div>
              <div className="col-12 order-pading">
                <span className="ps-text--order">
                  <i className="fa fa-map-marker color-blue"></i>
                  <span className="pl-10 title-font word-break-all">
                    {item.shippingaddress} {item.shippingcity} , {item.shippingstatename} {item.shippingcountryname}{" "}
                    {" - "}
                    {item.shippingpincode}
                  </span>
                </span>
              </div>
              <div className="col-12 order-pading">
                <span className="ps-text--order">
                  <i className="fa fa-building color-blue"></i>
                  <span className="pl-10 title-font word-break-all">{item.shippingcompanyname}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 pt-20 pb-10 box-rounded width-100 mt-20">
        <div className="ps-cart__content">
          <div className="ps-cart__items order-summary bn">
            <div className="row">
              <div className="col-12  mb-10">
                <h3 className="ps-form__title">Billing Address</h3>
              </div>

              <div className="col-12 order-pading">
                <span className="ps-text--order">
                  <i className="fa fa-user color-blue"></i>
                  <span className="pl-10 word-break-all color-blue">{item.billingname}</span>
                </span>
              </div>
              <div className="col-12 order-pading">
                <span className="ps-text--order">
                  <i className="fa fa-phone color-blue"></i>
                  <span className="pl-10 title-font">
                    {item.billingmobilecountry}
                    {item.billingmobile}
                  </span>
                </span>
              </div>
              <div className="col-12 order-pading">
                <span className="ps-text--order">
                  <i className="fa fa-map-marker color-blue"></i>
                  <span className="pl-10 title-font word-break-all">
                    {item.billingaddress} {item.billingcity} , {item.billingstatename} {item.billingcountryname} {" - "}
                    {item.billingpincode}
                  </span>
                </span>
              </div>
              <div className="col-12 order-pading">
                <span className="ps-text--order">
                  <i className="fa fa-building color-blue"></i>
                  <span className="pl-10 title-font word-break-all">{item.billingcompanyname}</span>
                </span>
              </div>
              <div className="col-12 order-pading">
                <span className="ps-text--order color-blue">GST {" :- "}</span>
                <span className="ps-text--order title-font"> {item.billinggst}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ));
  return <>{items}</>;
};

export default ShippingBillingAddress;
