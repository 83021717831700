import React, { useEffect } from "react";
import { Form } from "formik";
import * as Yup from "yup";

import shopconfig from "../../config/shopconfig.json";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import BreadCrumb from "../../components/elements/BreadCrumb";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import useFormAction from "../../hooks/useFormAction";

/*
  To initial values json
*/
const initialValues = {
  name: "",
  email: "",
  mobile: "",
  subject: "",
  message: "",
};

/*
  To form validation using yup
*/
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter value for Name")
    .matches(/^[^\s][a-zA-Z ]*$/, "Please enter valid Name"),
  mobile: Yup.string()
    .required("Please enter value for Mobile")
    .matches(/^[0-9]+$/, "Please enter valid Mobile no")
    .min(10, "Please enter minimum 10 digits Mobile no"),
  email: Yup.string().email().required("Please enter value for Email"),
  subject: Yup.string().required("Please enter value for Subject"),
  message: Yup.string().required("Please enter value for Message"),
});

const ContactUsTwo = () => {
  const { disable, setSuccessMessage, saveContactUs } = useFormAction();

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Contact Us",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-us-two">
      <Container>
        <GoogleCaptcha />
        <div className="ps-page form-loader">
          {disable && (
            <div className="ps-section__overlay">
              <div className="ps-section__loading"></div>
            </div>
          )}
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
              <h1 className="mt-20">Contact Us</h1>
            </div>
            <div className="contact-us-two-contact-page row">
              <div className="col-12 pl-0 pr-0 col-md-6 col-sm-12">
                <div className="form-section">
                  <h2>
                    Get in <span className="highlight">touch</span>
                  </h2>
                  <p>Any questions or remarks? Just write us a message!</p>
                  <FormikFrom
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                      saveContactUs(values);
                      resetForm("");
                    }}
                  >
                    <Form>
                      <InputField name={"name"} type={"text"} placeholder={"Name"} maxLength={"50"} id={"name"} />
                      <div className="city-row">
                        <InputField
                          name={"email"}
                          type={"email"}
                          placeholder={"Email"}
                          maxLength={"50"}
                          id={"email"}
                          classname="col-12"
                        />
                        <InputField
                          name={"mobile"}
                          type={"text"}
                          placeholder={"Mobile"}
                          maxLength={"10"}
                          id={"mobile"}
                          onKeyUp={() => {
                            document.getElementById("errormsg").classList.add("hide");
                            document.getElementById("errormsg").innerHTML = "";
                          }}
                          classname="col-12"
                        />
                      </div>
                      <InputField
                        name={"subject"}
                        type={"text"}
                        placeholder={"Subject"}
                        maxLength={"50"}
                        id={"subject"}
                      />
                      <InputField
                        name={"message"}
                        placeholder={"Write Your Message"}
                        maxLength={"500"}
                        component="textarea"
                        rows="3"
                      />
                      <div className="row">
                        <div className="col-12 color-green hide font-size-14 mb-20" id="contactus_successmsg">
                          Your details have been submitted. Our support team will contact you in next 24 hours
                        </div>
                      </div>
                      <span className="color-red hide" id="errormsg"></span>
                      <button
                        type="submit"
                        className={disable ? "submit-btn disabled" : "submit-btn"}
                        onClick={setSuccessMessage}
                      >
                        Submit
                      </button>
                    </Form>
                  </FormikFrom>
                </div>
              </div>
              <div className="col-12 pl-0 pr-0 col-md-6 col-sm-12">
                <div className="map-section">
                  <iframe
                    src={shopconfig.maplink}
                    title="Company Location"
                    allowFullScreen=""
                    loading="lazy"
                    height="450"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="info-container row">
              <div className="col-md-3 col-sm-12">
                <div className="contact-info">
                  <div className="info">
                    <div className="img-container">
                      <i class="fi fi-rr-phone-call"></i>
                    </div>
                    <span>{shopconfig.shop_contactno}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="contact-info">
                  <div className="info">
                    <div className="img-container">
                      <i class="fi fi-rr-envelope"></i>
                    </div>
                    <span>{shopconfig.email}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="contact-info">
                  <div className="info">
                    <div className="img-container">
                      <i class="fi fi-rr-alarm-clock"></i>
                    </div>
                    <span>{shopconfig.shop_week_days}</span>
                    <span>{shopconfig.shop_time}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="contact-info">
                  <div className="info">
                    <div className="img-container">
                      <i class="fi fi-rr-map-marker"></i>
                    </div>
                    <span>
                      {shopconfig.shop_address} {shopconfig.shop_city} {shopconfig.shop_pincode} {shopconfig.shop_state}
                      , {shopconfig.shop_country}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUsTwo;
