import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import NextArrow from "../../../../components/elements/carousel/NextArrow";
import PrevArrow from "../../../../components/elements/carousel/PrevArrow";
import config from "../../../../config/config.json";

/*
    To carousel setting
  */

const carouselSetting = {
  infinite: true,
  autoplay: true,
  speed: 100,
  slidesToShow: 1,
  arrows: true,
  dots: true,
  fade: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const HomeThreeTopBanners = ({ banner }) => {
  /*
    To home banner view
  */
  var bannerimage = "";
  if (banner && banner.bannerImages) {
    bannerimage = banner.bannerImages;
  }

  let bannerimages = [];
  let imagepath = "/static/img/banners/banner.png";
  let createbutton = "";
  if (bannerimage !== null && bannerimage !== "") {
    bannerimage.map((item, index) => {
      imagepath = config.apiUrl + config.image_path;
      if (item.image !== null && item.image !== "") {
        imagepath = imagepath + item.image;
      }

      if (item.displaybutton === 1) {
        let classname = "btn-" + item.horizontal + "-" + item.vertical;
        createbutton = (
          <Link to={item.buttonurl} className={"ps-btn ps-btn--warning " + classname} id={"button_" + index}>
            {item.buttontitle}
          </Link>
        );
      }
      bannerimages.push(
        <div className="carousel-item">
          <div className="ps-banner" style={{ background: "#F0F2F5" }}>
            <a href={item.bannerurl ? item.bannerurl : "javascript:;"} className="">
              <img className="ps-banner__image" src={imagepath} alt={item.imagealt} title={item.imagetitle}></img>
            </a>

            {item.displaybutton ? createbutton : ""}
          </div>
        </div>
      );
    });
  } else {
    bannerimages.push(
      <div className="carousel-item">
        <div className="ps-banner" style={{ background: "#F0F2F5" }}>
          <a>
            <img className="ps-banner__image" src={imagepath} alt={"noimage"} title={"noimage"}></img>
          </a>
          <button className="btn-center">Button</button>
        </div>
      </div>
    );
  }

  return (
    <div className="ps-section--banner  ps-top-banners">
      <Slider {...carouselSetting} className="ps-carousel ps-carousel--fullwidth">
        {bannerimages && bannerimages.length === 0 ? (<img className="ps-banner__image" src={imagepath} alt={"noimage"} title={"noimage"}></img>) : bannerimages
        }
      </Slider>
    </div>
  );
};
export default connect((state) => state)(HomeThreeTopBanners);
