export default function useLocalStorage() {
  return {
    blankLocalStorage: () => {
      localStorage.removeItem("authToken", "");
      localStorage.removeItem("userid", "");
      localStorage.removeItem("event", "");
      localStorage.removeItem("logindate", "");
      localStorage.removeItem("expires_in", "");
      localStorage.removeItem("refresh_token", "");
      localStorage.removeItem("SendOtpOn", "");
      localStorage.removeItem("SendOtp", "");
      localStorage.removeItem("otpevent", "");
      localStorage.removeItem("sameshipping", "");
      localStorage.removeItem("paymentmode", "");
      localStorage.removeItem("billingdata", "");
      localStorage.removeItem("note");
      localStorage.removeItem("EventOtp");
      localStorage.removeItem("appliedCoupon");
      localStorage.removeItem("shippingflag");
    },
  };
}
