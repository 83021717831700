import React, { useEffect } from "react";
import { Button, Form } from "antd";
import { Formik } from "formik";
import Modal from "react-bootstrap/Modal";

import ProductOnCart from '../../elements/products/ProductOnCart';
import ModuleDetailVariant from '../../elements/detail/modules/ModuleDetailVariant';

const ProductVariantModal = ({
  show,
  handleClose,
  productItem,
  isVarientobject,
  currencyrate,
  variantArray,
  groupedVariantArray,
  setSelectedVariant,
  product,
  addToCart
}) => {

  useEffect(() => {
    if (!show) {
      setSelectedVariant("");
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} className="modal-width mt-50" id="productform">
      <Modal.Header className="pt-15 pb-15" closeButton>
        <h2 className="ps-form__title font-size-24 mb-0">Choose Product Variant</h2>
      </Modal.Header>
      <Formik>
        {(formik) => {
          return (
            <Form>
              <Modal.Body>
                <div className="ps-form--review">
                  <div className="col-12">
                    <div className="row mt-20">
                      <div className="col-12">
                        <ProductOnCart
                          product={productItem}
                          varientobject={isVarientobject}
                          cartevent={"varientproduct"}
                          currencyrate={currencyrate}
                        />
                      </div>
                    </div>
                    <div className="row mt-20 mb-20">
                      <div className="col-12 pl-40">
                        {groupedVariantArray.length > 0 && groupedVariantArray.map((item, index) => (
                          <div key={index} className="ps-product__variants">
                            <ModuleDetailVariant
                              array={variantArray}
                              setname={setSelectedVariant}
                              name={item.name}
                              label={item.name}
                              values={item.values}
                              productid={product.id}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className={
                    isVarientobject.varientdisable === "Yes"
                      ? "ps-btn ps-btn--warning disabled disable-btn-color"
                      : "ps-btn ps-btn--warning "
                  }
                  onClick={(e) => {
                    if (isVarientobject.varientdisable !== "Yes") {
                      addToCart(e);
                    }
                  }}
                >
                  Choose
                </button>
                <Button variant="secondary" className="ps-btn ps-btn--warning close-btn" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ProductVariantModal;
