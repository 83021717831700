import { useState } from 'react'
import contactus from '../api/contactus';
import user from '../api/user';

const useFormAction = () => {
  const [disable, setDisable] = useState(false);

  /*
  To save contact us
*/
  const saveContactUs = async (data) => {
    setDisable(true);
    document.getElementById("errormsg").classList.add("hide");
    document.getElementById("errormsg").innerHTML = "";
    let regMobile = /^[0-9]{7,10}$/;
    if (regMobile.test(data.mobile)) {
      const res = await contactus.saveContactUs(data);
      if (res.data.status === 200) {
        setDisable(false);
        document.getElementById("contactus_successmsg").classList.remove("hide");
      } else {
        setDisable(false);
        document.getElementById("errormsg").classList.remove("hide");
        document.getElementById("errormsg").innerHTML = res.data.errors.message;
      }
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = "Invalid Mobile";
    }
  };

  const setSuccessMessage = () => {
    document.getElementById("contactus_successmsg").classList.add("hide");
  };

  /*
  To save news latter
 */
  const saveNewsLetter = async (value) => {
    if (value.subscribe) {
      var data = new Object();
      data["newsletter"] = value.subscribe;
      const res = await user.saveNewsLetter(data);
      if (res.data.status === 200) {
        document.getElementById("subscribefield").value = "";
        document.getElementById("successmsg").classList.remove("hide");
      }
    } else {
      document.getElementById("successmsg").classList.add("hide");
    }
  };


  return {
    disable,
    setDisable,
    saveContactUs,
    setSuccessMessage,
    saveNewsLetter
  }
}

export default useFormAction