import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import cart from "../../../api/cart";
import order from "../../../api/order";
import user from "../../../api/user";
import config from "../../../config/config.json";
import shopconfig from "../../../config/shopconfig.json";
import { currencyPaypalPrice, currencyPrice } from "../../../hooks/currencyCalculation";
import useEcomerce from "../../../hooks/useEcomerce";
import useUser from "../../../hooks/useUser";
import CaculateDiscount from "../../../pages/cart/CaculateDiscount";
import CartParameter from "../../../pages/cart/CartParameter";
import SpecialDiscountCalculation from "../../../pages/cart/SpecialDiscountCalculation";
import LocalstorageParam from "../../../pages/common/LocalstorageParam";
import { calculateAmount } from "../../../utilities/ecomerce-helpers";
import { setCartItems } from "../../../store/ecomerce/action";
import GoogleCaptcha from "../../common/GoogleCaptcha";

var object = new Object();
var couponobject = new Object();
var paymentobject = new Object();
var productobject = new Object();
var payumoney;
let couponproductlist = [];
/*
  To manage shipping json
*/
const shippingdata = {
  shippingstate: "",
  shippingcountry: "",
  subtotal: "",
  orderproductlist: [],
  shippingpincode: "",
};

const ModuleEcomerceCartSummary = ({
  ecomerce,
  cartItems,
  cartevent,
  sameshippingdata,
  setSpecialPrice,
  setapplyCoupon,
  currencyrate,
  isdefaultaddress,
  onSetCartlist,
  setHandelloader,
  proceedFlag,
  selectedPaymentMethod,
}) => {
  const { removeItems } = useEcomerce();
  const { PaymentGateway, getpaymentgateway } = useEcomerce();
  const { userReward, getUserRewards, userRewardUseAndAmount, getUserRewardsAndAmount } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  let Razorpay = useRazorpay();

  const [shipping, setShipping] = useState(0);
  const [disable, setDisable] = useState(false);
  const [payUData, setPayUData] = useState();
  const [paypalbtn, setPaypalBtn] = useState(false);
  const [discount, setDiscount] = useState("");
  const [coupontype, setCoupontype] = useState(localStorage.getItem("coupontype"));
  const [SubTotal, setSubTotal] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [couponcode, setCouponcode] = useState("");
  const [IsFlag, setIsFlag] = useState();
  const [ErrorMsg, setErrorMsg] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [request, setRequest] = useState("");
  const [pinfound, setPinFound] = useState(true);
  const [totaldiscountonprice, setTotaldiscountonprice] = useState();

  const [show, setShow] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState(-1);

  const [isRewardPointsApply, setIsRewardPointsApply] = useState(false);
  const [rewardPoints, setRewardPoints] = useState(0);

  const [paymentObject, setPaymentObject] = useState(paymentobject);

  const [isVisible, setIsVisible] = useState(true);
  const [shippingCalData, setShippingCalData] = useState({ standardshipping: 0, expressshipping: 0 });
  const [selectedshipping, setSelectedshipping] = useState(localStorage.getItem("shippingflag") || "standard");

  const isShippingVisible = Number(shippingCalData.standardshipping) > 0 || Number(shippingCalData.expressshipping) > 0;

  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();

  const [discountcoupon, setDiscountcoupon] = useState(searchparam.urlcoupon);
  const [validCoupon, setValidCoupon] = useState(searchparam.urlcoupon);

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  let billing;
  let amount_currency;
  var promise1;
  let totalView;

  /*
    To use Maximum points
  */
  const maxPointToUse = userRewardUseAndAmount && userRewardUseAndAmount.maxorderrewardpoint;

  /*
      To store valid points
    */
  const rewardpoint =
    userReward && (userReward.rewardpoints >= maxPointToUse ? maxPointToUse : userReward.rewardpoints);

  /*
      To store reward count for calculation
    */
  const rewardCount = localStorage.getItem("isRewardPointsApply") === "true" ? rewardPoints : 0;

  useEffect(() => {
    object = new Object();
    getpaymentgateway();
    getAddressList("");
    getCouponList();
    getUserRewards();
    getUserRewardsAndAmount();
    setIsFlag(true);
    if (couponcode) {
      setDiscountcoupon(couponcode);
    } else {
      setDiscountcoupon(searchparam.urlcoupon);
    }
    if (ecomerce.cartItems) {
      /*
        To set billing data in json object
       */
      if (
        localparam &&
        localparam.billingdata &&
        localparam.billingdata !== null &&
        localparam.billingdata !== "" &&
        localparam.billingdata !== "undefined"
      ) {
        try {
          billing = JSON.parse(localparam.billingdata || "{}");
          object = billing;
        } catch (error) {
          console.error("Error parsing billing Data", error);
        }
      }
      object["billingaddresschange"] = localparam.sameshipping;
      object["paymentstatus"] = "Pending";
      object["status"] = "Placed";
      if (localStorage.getItem("note")) {
        object["customernote"] = localStorage.getItem("note");
      }
    }

    discountCalculation();

    if (coupontype === "" || coupontype !== "SHIPPING") {
      if (discountcoupon && discountcoupon !== null && discountcoupon !== "") {
        applyCoupon("");
      }
    }
    /*
      To payumoney button hide /show using class add and remove
    */
    if (payUData && payUData !== null && payUData !== "") {
      document.getElementById("paynowbtn").click();
      document.getElementById("payumoneydiv").classList.add("hide");
      document.getElementById("payumoneydiv").classList.remove("hide");
      window.scrollTo(0, 0);
    }
    if (rewardpoint) {
      localStorage.setItem("rewardpoints", rewardpoint);
    }
    setRewardPoints(rewardpoint);
    localStorage.setItem("rewardpoints", rewardpoint);
    setCartListInJson();
    finalAmountCalculation();
    setCurrencyList();
  }, [
    ecomerce,
    payUData,
    discountcoupon,
    cartItems,
    searchparam.urlcoupon,
    searchparam.urlpoints,
    isdefaultaddress,
    IsFlag,
    show,
    rewardPoints,
    maxPointToUse,
  ]);

  useEffect(() => {
    localStorage.setItem("appliedCoupon", "");
    localStorage.setItem("coupontype", "");
    setIsRewardPointsApply(false);
  }, []);

  useEffect(() => {
    const storedCode = localStorage.getItem("appliedCoupon");
    if (storedCode !== null) {
      setSelectedOffer(storedCode);
    }
    if (discount.couponcode !== undefined) {
      setSelectedOffer(discount.couponcode);
    }
    const hideShippingOption = location.pathname.startsWith("/ordersummary");
    setIsVisible(!hideShippingOption);
  }, [selectedOffer, discount.couponcode]);

  useEffect(() => {
    if (selectedshipping) {
      localStorage.setItem("shippingflag", selectedshipping);
    }
  }, [selectedshipping]);

  useEffect(() => {
    const storedRewardPoints = localStorage.getItem("rewardpoints");
    setRewardPoints(storedRewardPoints);
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("rewardpoints") === null && localStorage.getItem("isRewardPointsApply") === "true") {
      localStorage.setItem("isRewardPointsApply", "false");
    }
  }, []);

  /*
   To open and close modal
 */
  const handleClose = () => {
    setShow(false);
    document.body.classList.add("overflow-auto");
  };
  const handleShow = () => {
    setShow(true);
    document.body.classList.remove("overflow-auto");
  };

  const handleUseRewardPoints = () => {
    setIsRewardPointsApply(true);
    setRewardPoints(rewardpoint);
    localStorage.setItem("rewardpoints", rewardpoint);
    localStorage.setItem("isRewardPointsApply", "true");
    var url = new URL(window.location.href);
    var search_params = url.searchParams;
    search_params.set("rewardpoints", rewardPoints);

    url.search = search_params.toString();
    var new_url = url.toString();
    window.history.pushState(null, "", new_url);
  };

  const handleRemoveRewardPoints = () => {
    setIsRewardPointsApply(false);
    setRewardPoints(null);
    localStorage.removeItem("rewardpoints");
    localStorage.setItem("isRewardPointsApply", "false");
    var url = new URL(window.location.href);
    var search_params = url.searchParams;
    search_params.delete("rewardpoints", rewardPoints);

    url.search = search_params.toString();
    var new_url = url.toString();
    window.history.pushState(null, "", new_url);
  };

  /*
     To get coupons 
   */
  const getCouponList = async () => {
    try {
      const response = await order.getCoupon();
      setCoupons((prevCoupons) => {
        if (response && response.data && response.data.payload) {
          const updatedCoupons = response.data.payload;
          return updatedCoupons;
        }
        return prevCoupons;
      });
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  /*
    To get address list by address id and set in object
  */
  const getAddressList = async (addressid) => {
    var addressobject = new Object();
    if (addressid) {
      addressobject["addressid"] = addressid;
    }

    addressobject["isdefaultaddress"] = "1";
    const res = await user.getAddress(addressobject);
    if (res.data.status === 200) {
      if (res.data.payload.length > 0) {
        shippingdata.shippingstate = res.data.payload[0].state;
        shippingdata.shippingcountry = res.data.payload[0].country;
        shippingdata.shippingpincode = res.data.payload[0].pincode;
        shippingCalculation();
        setDisable(false);
        setErrorMsg("");
        setIsFlag(true);
      } else {
        if (cartevent !== "cart") {
          setDisable(true);
          setIsFlag(false);
        }
      }
    }
  };

  /*
    To cart calculation for subtotal,total and set in json
  */
  const finalAmountCalculation = () => {
    if (cartItems) {
      let totalamount;

      if (cartItems && cartItems.length > 0) {
        totalView = calculateAmount(cartItems);
        object["subtotal"] = totalView;
        shippingdata.subtotal = totalView;

        totalamount =
          Number(totalView) +
          Number(shipping) -
          Number(CaculateDiscount(cartItems, object, discount)) -
          Number(rewardCount);
        object["total"] = totalamount;

        amount_currency = Number(totalamount);
        if (currencyrate) {
          amount_currency = (Number(totalamount) * Number(currencyrate.multiplierrate).toFixed(5)).toFixed(2);
        }

        setTotalAmount(totalamount);
      } else {
        totalView = "0.00";
      }
      setSubTotal(totalView);
    }
  };

  /*
    To set data in cart json for shipping
  */
  const setCartListInJson = () => {
    var flag = true;
    if (cartItems && cartItems.length > 0) {
      let cart_list = [];
      let produlist = [];

      var i = 0;
      cartItems.forEach((element) => {
        element.producttitle = element.productname;
        let orderproductlist = {
          quantity: "",
          weight: "",
        };
        if (flag) {
          if (Number(element.stock) < Number(element.quantity)) {
            flag = false;
            setIsFlag(false);
          }
        }

        orderproductlist.quantity = element.quantity;
        orderproductlist.weight = Number(element.weight);

        cart_list.push(orderproductlist);
        cartItems[i].total = Number(element.quantity) * Number(element.price);
        if (Number(element.offerprice) > 0) {
          cartItems[i].total = Number(element.quantity) * Number(element.offerprice);
        }
        cartItems[i].productvariantdetailid = element.productvariantid;

        let requestBody = {
          productid: element.productid,
          productvariantid: element.productvariantid,
          quantity: cartItems[i].quantity,
        };
        produlist.push(requestBody);

        i++;
      });
      productobject["requestBody"] = produlist;

      shippingdata.orderproductlist = cart_list;
    }
  };

  /*
    To shipping calculation using address data and cart data
  */
  const shippingCalculation = async () => {
    setPinFound(true);
    if (cartItems && cartItems.length > 0) {
      let res = await cart.shippingCalculation(shippingdata);
      if (res.status === 200) {
        let shippingcalculation = res.data.payload;
        setShippingCalData(shippingcalculation);

        if (shippingcalculation) {
          let selectedShippingRate =
            selectedshipping === "standard"
              ? shippingcalculation.standardshipping
              : shippingcalculation.expressshipping;

          setShipping(selectedShippingRate);
          object["shipping"] = selectedShippingRate;
          object["shippingflag"] = selectedshipping;
          localStorage.setItem("shipping", selectedShippingRate);
          localStorage.setItem("shippingflag", selectedshipping);

          if (shippingcalculation.pinfound === 0) {
            setPinFound(false);
          }
        }

        if (coupontype === "SHIPPING" && discountcoupon) {
          applyCoupon(shipping);
        }
      }
    }
  };

  /*
    To save order and check product stock
  */
  const saveOrder = async () => {
    /*
      To check product stock
    */
    localStorage.setItem("paymentmode", "");
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        setDisable(true);
        setCurrencyList();
        /*
          To save order 
        */
        localStorage.setItem("paymentmode", "Paypal");
        object["paymentmode"] = "Paypal";
        object["orderfrom"] = "WEBSITE";
        object["shippingflag"] = selectedshipping;
        if (localStorage.getItem("isRewardPointsApply") === "true") {
          object["rewardpointapply"] = true;
          object["point"] = Number(rewardPoints);
        }
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          paymentobject["orderid"] = res.data.payload.orderid;
          paymentobject["value"] = res.data.payload.orderidvalue;
          paymentobject["paymentmode"] = "Paypal";
        } else {
          setDisable(false);
        }
      }
    }
  };

  /*
    To update order status after transaction successfully complete
  */
  const updateStatus = async () => {
    paymentobject["paymentstatus"] = "Success";
    setDisable(true);
    const res = await cart.orderupdate(paymentobject);
    if (res.data.status === 200) {
    } else {
      setDisable(false);
    }
  };

  /*
    To razorPay integration , save order and check product stock
  */
  const razorPay = async () => {
    localStorage.setItem("paymentmode", "");
    if (setHandelloader) {
      setHandelloader(true);
    }
    /*
      To check product stock
    */
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        localStorage.setItem("paymentmode", "Razorpay");
        setDisable(true);
        setCurrencyList();
        /*
          To save order 
        */
        object["paymentmode"] = "Razorpay";
        object["orderfrom"] = "WEBSITE";
        object["shippingflag"] = selectedshipping;
        if (localStorage.getItem("isRewardPointsApply") === "true") {
          object["rewardpointapply"] = true;
          object["point"] = Number(rewardPoints);
        }
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          if (Number(res.data.payload.amount) > 0) {
            paymentobject["orderid"] = res.data.payload.orderid;
            paymentobject["value"] = res.data.payload.orderidvalue;
            paymentobject["paymentmode"] = "Razorpay";
            const options = {
              key: PaymentGateway.rpkeyid,
              amount: currencyPrice(Number(res.data.payload.amount), currencyrate),
              currency: currencyrate.currencycode,
              name: shopconfig.shop_name,
              description: "Transaction for a new purchase from website",
              image: config.applicationUri + shopconfig.shop_logo,
              order_id: res.data.payload.razorpayid,
              handler: function (response) {
                paymentobject["transactionid"] = response.razorpay_order_id;
                paymentobject["paymentid"] = response.razorpay_payment_id;
                setDisable(false);
                updateStatus();
                removeItems("cart");
                navigate("/ordersuccess", {
                  state: { paymentObject: paymentobject, value: paymentobject.orderidvalue },
                });
                if (setHandelloader) {
                  setHandelloader(false);
                }
              },
              prefill: {
                name: res.data.payload.name,
                email: res.data.payload.email,
                contact: res.data.payload.contactno,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              modal: {
                ondismiss: function () {
                  setDisable(false);
                  if (setHandelloader) {
                    setHandelloader(false);
                  }
                },
              },
            };

            const rzp1 = new Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              paymentobject["paymentstatus"] = "Failed";
              paymentobject["transactionid"] = response.error.metadata.payment_id;
              paymentobject["paymentid"] = response.error.metadata.order_id;
              updateStatus();
              navigate("/orderfailed");
              setDisable(false);
              if (setHandelloader) {
                setHandelloader(false);
              }
            });

            rzp1.open();
          }
        } else {
          setDisable(false);
          if (setHandelloader) {
            setHandelloader(false);
          }
        }
      }
    }
  };

  /*
     To Stripe integration , save order and check product stock
   */
  const payWithStripe = async () => {
    localStorage.setItem("paymentmode", "");
    if (setHandelloader) {
      setHandelloader(true);
    }
    /*
      To check product stock
    */
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        localStorage.setItem("paymentmode", "Stripe");
        setDisable(true);
        setCurrencyList();
        /*
          To save order   
        */
        object["paymentmode"] = "Stripe";
        object["orderfrom"] = "WEBSITE";
        object["shippingflag"] = selectedshipping;
        if (localStorage.getItem("isRewardPointsApply") === "true") {
          object["rewardpointapply"] = true;
          object["point"] = Number(rewardPoints);
        }
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          localStorage.removeItem("tnxid");
          localStorage.removeItem("merchanttransactionid");
          localStorage.setItem("ordervalueid", res.data.payload.orderidvalue);
          localStorage.setItem("checkoutsessionid", res.data.payload.checkoutsessionid);
          localStorage.setItem("paymentmode", selectedPaymentMethod);
          localStorage.setItem("orderid", res.data.payload.orderid);

          const paymentLink = res.data.payload.stripepaymentlink;
          if (paymentLink) {
            window.location.href = paymentLink;
          }
        } else {
          setDisable(false);
          if (setHandelloader) {
            setHandelloader(false);
          }
        }
      }
    }
  };

  /*
   UseEffect for Stripe, Pay U and Phone Pe to send paymentobject
  */

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success") || query.get("paymentid")) {
      const paymentIdFromUrl = query.get("paymentid");
      localStorage.setItem("paymentid", paymentIdFromUrl);

      paymentobject["orderid"] = localStorage.getItem("orderid");
      paymentobject["value"] = localStorage.getItem("ordervalueid");
      paymentobject["paymentmode"] = localStorage.getItem("paymentmode");
      paymentobject["transactionid"] =
        localStorage.getItem("checkoutsessionid") ||
        localStorage.getItem("merchanttransactionid") ||
        localStorage.getItem("paymentid");
      paymentobject["paymentid"] = localStorage.getItem("paymentid");

      updateStatus();
      removeItems("cart");
      navigate("/ordersuccess", { state: { paymentObject: paymentobject, value: paymentobject.orderidvalue } });
    }

    if (query.get("canceled")) {
      paymentobject["paymentstatus"] = "Failed";
      navigate("/orderfailed");
    }
  }, []);

  /*
    To pay with CCAvenue
 */
  const payWithCCAvenue = async (event) => {
    event.preventDefault();

    localStorage.setItem("paymentmode", "");
    if (setHandelloader) {
      setHandelloader(true);
    }
    /*
      To check product stock
    */
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        localStorage.setItem("paymentmode", "CCAvenue");
        setDisable(true);
        setCurrencyList();

        /*
          To save order
        */
        object["paymentmode"] = "CCAvenue";
        object["orderfrom"] = "WEBSITE";
        object["shippingflag"] = selectedshipping;
        if (localStorage.getItem("isRewardPointsApply") === "true") {
          object["rewardpointapply"] = true;
          object["point"] = Number(rewardPoints);
        }
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          if (Number(res.data.payload.amount) > 0) {
            paymentobject["orderid"] = res.data.payload.orderid;
            paymentobject["value"] = res.data.payload.orderidvalue;
            paymentobject["paymentmode"] = "CCAvenue";
            document.redirect.access_code.value = res.data.payload.access_code;
            document.redirect.encRequest.value = res.data.payload.encry_key;

            document.redirect.submit();
          }
        } else {
          setDisable(false);
          if (setHandelloader) {
            setHandelloader(false);
          }
        }
      }
    }
  };

  /*
    To redirect to summery after save billing data
  */
  const redirectToSummery = () => {
    if (document.getElementById("savebillingbtn")) {
      document.getElementById("savebillingbtn").click();
    }
  };

  /*
    To show paypal button
  */
  const payPal = () => {
    setPaypalBtn(true);
  };

  /*
    To save order by PayUMoney
  */
  const saveOrderPayUMoney = async () => {
    localStorage.setItem("paymentmode", "");
    /*
      To check product stock
   */
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        localStorage.setItem("paymentmode", "PayU");
        setDisable(true);
        setCurrencyList();
        object["paymentmode"] = "PayU";
        object["orderfrom"] = "WEBSITE";
        object["shippingflag"] = selectedshipping;
        if (localStorage.getItem("isRewardPointsApply") === "true") {
          object["rewardpointapply"] = true;
          object["point"] = Number(rewardPoints);
        }
        /*
          To save order 
        */
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          localStorage.removeItem("merchanttransactionid");
          localStorage.removeItem("checkoutsessionid");
          localStorage.setItem("ordervalueid", res.data.payload.orderidvalue);
          localStorage.setItem("txnid", res.data.payload.paymentvalues.txnid);
          localStorage.setItem("paymentmode", selectedPaymentMethod);
          localStorage.setItem("orderid", res.data.payload.orderid);

          setPayUData(res.data.payload);
          setDisable(false);
        } else {
          setDisable(false);
        }
      }
    }
  };

  /*
    To pay with Phone Pe 
  */
  const payWithPhonePe = async () => {
    localStorage.setItem("paymentmode", "");
    if (setHandelloader) {
      setHandelloader(true);
    }

    /*
      To check product stock
    */
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        localStorage.setItem("paymentmode", "PhonePe");
        setDisable(true);
        setCurrencyList();
        object["paymentmode"] = "PhonePe";
        object["orderfrom"] = "WEBSITE";
        object["shippingflag"] = selectedshipping;
        if (localStorage.getItem("isRewardPointsApply") === "true") {
          object["rewardpointapply"] = true;
          object["point"] = Number(rewardPoints);
        }
        /*
          To save order 
        */
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          localStorage.removeItem("checkoutsessionid");
          localStorage.removeItem("txnid");
          localStorage.setItem("ordervalueid", res.data.payload.orderidvalue);
          localStorage.setItem("merchanttransactionid", res.data.payload.merchanttransactionid);
          localStorage.setItem("paymentmode", selectedPaymentMethod);
          localStorage.setItem("orderid", res.data.payload.orderid);

          const paymentLink = res.data.payload.phonepepaymentlink;
          if (paymentLink) {
            window.location.href = paymentLink;
          }
        } else {
          setDisable(false);
        }
      }
    }
  };

  /*
    To delete coupon and reset cart list and redirect to cart
  */
  const deleteCoupon = () => {
    setDiscountcoupon("");
    setDiscount("");
    setapplyCoupon("");
    setCoupontype("");
    setCouponcode("");
    setAppliedCoupon("");
    setValidCoupon("");
    setSelectedOffer("");
    localStorage.removeItem("coupontype");
    localStorage.removeItem("appliedCoupon");
    document.getElementById("couponmsg").innerHTML = "";
    cartItems.forEach((item) => {
      if (Number(item.offerprice) > 0) {
        item.offerprice = item.productofferprice;
      } else {
        item.offerprice = 0;
      }
      item.isproductcoupon = "No";
    });
    navigate("/cart");
  };

  /*
    To set price
  */
  const setProductTotalPrice = (cartid) => {
    if (document.getElementById("price_" + cartid)) {
      document.getElementById("price_" + cartid).classList.remove("hide");
    }
    if (document.getElementById("after_discount_" + cartid)) {
      document.getElementById("after_discount_" + cartid).classList.add("hide");
    }
  };

  /*
    To apply coupon
  */
  const applyCoupon = async (shippingamount) => {
    setCouponcode("");
    couponproductlist = [];
    cartItems.forEach((element) => {
      let couponproductlistBody = {
        id: element.id,
        productid: element.productid,
        productvariantdetailid: element.productvariantid,
        categoryid: element.categoryid,
        taxpercentage: 0,
        quantity: element.quantity,
        price: element.price,
        offerprice: Number(element.offerprice).toFixed(2),
      };
      couponproductlist.push(couponproductlistBody);
    });
    var shippping_amount = shipping;

    couponobject["orderproductlist"] = couponproductlist;
    couponobject["discountcoupon"] = discountcoupon;
    couponobject["subtotal"] = calculateAmount(cartItems);
    couponobject["total"] =
      Number(calculateAmount(cartItems)) + Number(shipping) - Number(CaculateDiscount(cartItems, object, discount));
    if (shippingamount) {
      shippping_amount = shippingamount;
    }

    couponobject["shipping"] = localStorage.getItem("shipping");
    if (couponproductlist && couponproductlist.length > 0) {
      const res = await order.couponValidation(couponobject);
      if (res.data && res.data !== "") {
        if (res.data.status === 200) {
          setDiscount(res.data.payload.data[0]);
          setCoupontype(res.data.payload.data[0].discounttype);
          setDiscountcoupon(discountcoupon);

          localStorage.setItem("coupontype", res.data.payload.data[0].discounttype);
          object["discountcoupon"] = res.data.payload.data[0].discountcoupon;
          object["couponcode"] = discountcoupon;
          setCartItems([...cartItems]);

          setProductDiscount(res.data.payload.data[0]);
          if (document.getElementById("couponerrormsg")) {
            document.getElementById("couponerrormsg").innerHTML = "";
          }
          if (document.getElementById("couponmsg")) {
            document.getElementById("couponmsg").innerHTML = "";
          }

          setValidCoupon(discountcoupon);

          var url = new URL(window.location.href);
          var search_params = url.searchParams;
          search_params.set("coupon", discountcoupon);

          url.search = search_params.toString();
          var new_url = url.toString();
          window.history.pushState(null, "", new_url);
        } else {
          if (
            "Invalid Coupon Code" === res.data.errors.message ||
            "Invalid Coupon code - This Coupon code limit is over." === res.data.errors.message ||
            "Invalid Coupon code - This coupon code is not valid for you." === res.data.errors.message ||
            "Invalid Coupon" === res.data.errors.message
          ) {
            if (document.getElementById("couponerrormsg")) {
              document.getElementById("couponerrormsg").innerHTML = res.data.errors.message;
              setCouponcode("");
            }
            if (document.getElementById("couponmsg")) {
              document.getElementById("couponmsg").innerHTML = "";
            }
          } else {
            if (document.getElementById("couponmsg")) {
              document.getElementById("couponmsg").innerHTML = res.data.errors.message;
              setSelectedOffer(discount.couponcode);
            }
            if (document.getElementById("couponerrormsg")) {
              document.getElementById("couponerrormsg").innerHTML = "";
            }
          }
        }
      }
    }
  };

  /*
    To Product Discount set in cart list when apply product coupon
  */
  const setProductDiscount = (data) => {
    const updateCartItems = cartItems.map((item) => {
      const productInList = data.orderproductlist.find((p) => Number(p.id) === Number(item.id));
      if (data.discounttype === "PRODUCT" && productInList) {
        const totalprice = Number(productInList.price);
        const discount = Number(productInList.discount);
        const totaldiscountonprice = Number(productInList.discount) * Number(productInList.quantity);
        setTotaldiscountonprice(totaldiscountonprice);
        const newprice = totalprice - discount;

        if (newprice !== totalprice) {
          item.discounedprice = newprice.toFixed(2);
          item.discount = totaldiscountonprice.toFixed(2);
          if (productInList.discountapply === "Yes" && totaldiscountonprice > 0) {
            item.isproductcoupon = "Yes";
            item.offerprice = newprice;
          } else {
            item.isproductcoupon = "Yes";
          }
        } else if (productInList.discountapply === "No") {
          item.offerprice = 0;
          item.discount = 0;
        } else if (productInList.discountapply === null) {
          item.offerprice = 0;
          item.discount = 0;
        }
      } else if (["CART", "SHIPPING", "BUYXGETY"].includes(data.discounttype)) {
        item.offerprice = 0;
        item.isproductcoupon = "Yes";
        setProductTotalPrice(item.id);
      }
      return item;
    });

    setCartItems(updateCartItems);
    setapplyCoupon(data.discounttype);
    setSpecialPrice(data);
  };

  /*
    To set cart list for order
  */
  const setCurrencyList = () => {
    if (cartItems && cartItems.length > 0) {
      if (currencyrate) {
        var currencydata = {
          id: currencyrate.id,
          currencyname: currencyrate.currencyname,
          displayicon: currencyrate.displayicon,
          currencycode: currencyrate.currencycode,
          rate: currencyrate.rate,
          multiplier: currencyrate.multiplier,
          multiplierrate: Number(currencyrate.multiplierrate).toFixed(5),
        };
        object["currencyForm"] = currencydata;
      }
    }
  };

  /*
    To set coupon in state variable
  */
  const setCoupon = (e) => {
    e.preventDefault();
    localStorage.removeItem("coupontype");
    let coupon_code = document.getElementById("coupon").value.trim();
    if (coupon_code) {
      setDiscountcoupon(coupon_code);
      setCouponcode(coupon_code);
      setValidCoupon(coupon_code);
    }
    document.getElementById("coupon").value = "";
  };

  /*
  To set coupon in state variable from list of coupons
  */
  const setCounponFromList = (couponCode, index) => {
    setSelectedOffer(couponCode);
    setAppliedCoupon(couponCode);
    setValidCoupon(couponCode);
    localStorage.removeItem("coupontype");
    setDiscountcoupon(couponCode);
    setCouponcode(couponCode);
    setShow(false);
    document.body.classList.add("overflow-auto");
    localStorage.setItem("appliedCoupon", couponCode);
  };

  /*
    To discount calculation
  */
  const discountCalculation = () => {
    /*
      To set offer and special discount 
    */
    if (
      discount === "" ||
      (discount.discounttype !== "PRODUCT" &&
        discount.discounttype !== "SHIPPING" &&
        discount.discounttype !== "CART" &&
        discount.discounttype !== "BUYXGETY")
    ) {
      if (cartItems && cartItems.length > 0) {
        cartItems.forEach((element, index) => {
          let offer = 0;
          if (Number(element.productofferprice) > 0) {
            offer = Number(element.price) - Number(element.productofferprice);
            offer = Number(offer) * Number(element.quantity);
          }

          cartItems[index].isproductcoupon = "No";
          cartItems[index].discount = Number(offer);
          promise1 = SpecialDiscountCalculation.specialCalculation(element);
          Promise.all([promise1]).then(function (values) {
            if (values) {
              if (values[0].isproductcoupon === "No") {
                setSpecialPrice(values);
              }
            }
          });
        });
        setCartItems(cartItems);
      }
    }
  };

  /*
    To show shipping error msg
  */
  const showError = () => {
    if (disable) {
      setErrorMsg("Please select shipping address");
      document.getElementById("shippingerrormsg").classList.remove("hide");
    }
  };

  /*
   To paypal total amount
 */
  const paypalUSDAmount = currencyPaypalPrice(
    Number(SubTotal) + Number(shipping) - Number(CaculateDiscount(cartItems, object, discount)),
    currencyrate
  );

  return (
    <>
      <div className="ps-block--cart-summary">
        <h3>Cart Total</h3>
        <div className="ps-block__content bn card-box-shadow  p-20 mt-30">
          <div className="ps-block__records">
            <p>
              <span className="font-size-18">Subtotal</span>
              <strong className="cart-font-title">{currencyPrice(SubTotal, currencyrate)}</strong>
            </p>
            <p>
              <span className="font-size-18" style={{ flex: 1 }}>
                Shipping
                {isVisible === false && selectedshipping !== null && (
                  <span className="font-size-12 cart-font-title border rounded px-1 py-0 ml-1">
                    {selectedshipping === "standard" ? "Standard" : "Express"}
                  </span>
                )}
                {cartevent === "cart" && (
                  <>
                    <br></br>
                    <strong className="font-weight-300 font-size-12">[Based on your default shipping address]</strong>
                  </>
                )}
              </span>
              <span>
                {shipping > 0 ? (
                  <strong className="cart-font-title">{currencyPrice(shipping, currencyrate)}</strong>
                ) : (
                  <>
                    {pinfound ? (
                      <strong className="cart-font-title">Free</strong>
                    ) : (
                      <>
                        <span className="" style={{ color: "red" }}>
                          Pincode is not serviceable
                        </span>
                      </>
                    )}
                  </>
                )}
              </span>
            </p>
            <p>
              <span className="font-size-18">Discount</span>
              <strong className="cart-font-title">
                <span className="cart-font-title pl-0">
                  {currencyPrice(CaculateDiscount(cartItems, object, discount), currencyrate)}
                </span>
              </strong>
            </p>
            {discount && discount !== null && discount !== "" && discount.couponcode && discount.coupocode !== "" && (
              <p>
                <span className="font-size-18">Coupon</span>
                <strong className="cart-font-title">
                  <span>
                    <span className="cart-font-title">{discount.couponcode}</span>
                    {cartevent === "cart" && (
                      <span className="pl-10">
                        <a onClick={deleteCoupon}>
                          <i className="fa fa-trash color-red"></i>
                        </a>
                      </span>
                    )}
                  </span>
                </strong>
              </p>
            )}
            {localStorage.getItem("isRewardPointsApply") === "true" && rewardPoints > 0 && (
              <p>
                <span className="font-size-18">
                  Reward Points<br></br>
                  <strong className="font-weight-300 font-size-12">[ Maximum usage : {maxPointToUse} Points ]</strong>
                </span>

                <strong className="cart-font-title">
                  <span>
                    <span className="cart-font-title">{parseFloat(rewardPoints).toFixed(1)}</span>

                    {cartevent === "cart" && (
                      <span className="pl-10">
                        <a onClick={handleRemoveRewardPoints}>
                          <i className="fa fa-trash color-red"></i>
                        </a>
                      </span>
                    )}
                  </span>
                </strong>
              </p>
            )}
            <p className="total">
              <span className="font-size-18">
                Total <br></br>
                <strong className="font-weight-300 font-size-12">[Inclusive of all taxes]</strong>
              </span>
              <strong className="cart-font-title">
                <span className="cart-font-title pl-0">
                  {currencyPrice(
                    Number(SubTotal) +
                      Number(shipping) -
                      Number(CaculateDiscount(cartItems, object, discount)) -
                      Number(rewardCount),
                    currencyrate
                  )}
                </span>
              </strong>
            </p>
          </div>
        </div>
        {isVisible && isShippingVisible && (
          <div className="card-box-shadow p-20 mt-30">
            <p className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                <h3>Choose Shipping</h3>
                <span className="pl-5 d-flex">
                  <i className="fi fi-rr-shipping-fast shipping-logo"></i>
                </span>
              </div>
              <div className="d-flex justify-content-between mr-2">
                <label className="font-size-16 cart-font-title fw-bold">
                  <input
                    type="radio"
                    name="shipping"
                    className="mr-2"
                    value="standard"
                    data-shipping-cost={Number(shippingCalData.standardshipping)}
                    checked={selectedshipping === "standard"}
                    onChange={(e) => {
                      setSelectedshipping(e.target.value);
                      setShipping(parseFloat(e.target.getAttribute("data-shipping-cost")));
                      localStorage.setItem("shipping", e.target.value);
                    }}
                    key="standard"
                  />
                  Standard Shipping
                </label>
                <label className="font-size-16 cart-font-title fw-bold">
                  {currencyPrice(shippingCalData.standardshipping, currencyrate)}
                </label>
              </div>
              <div className="d-flex justify-content-between mr-2">
                <label className="font-size-16 cart-font-title">
                  <input
                    type="radio"
                    name="shipping"
                    className="mr-2"
                    value="express"
                    data-shipping-cost={Number(shippingCalData.expressshipping)}
                    checked={selectedshipping === "express"}
                    onChange={(e) => {
                      setSelectedshipping(e.target.value);
                      setShipping(parseFloat(e.target.getAttribute("data-shipping-cost")));
                      localStorage.setItem("shipping", e.target.value);
                    }}
                    key="express"
                  />
                  Express Shipping
                </label>
                <label className="font-size-16 cart-font-title fw-bold">
                  {currencyPrice(shippingCalData.expressshipping, currencyrate)}
                </label>
              </div>
            </p>
          </div>
        )}
        {cartevent === "cart" && (
          <div className="card-box-shadow p-20 mt-30">
          <GoogleCaptcha />
            <div className="ps-cart__actions">
              <div className="ps-cart__coupon ps-cart-coupon">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your coupon"
                  id="coupon"
                  onChange={(e) => setCouponcode(e.target.value)}
                />
                <button
                  className={`ps-btn ps-btn--primary ${couponcode.trim() === "" ? "disabled btn lighter" : ""}`}
                  onClick={(e) => {
                    setCoupon(e);
                  }}
                  disabled={couponcode.trim() === ""}
                >
                  Apply
                </button>
              </div>
              <button class="btn btn-link btn-lg pl-1" onClick={handleShow}>
                View all offers
              </button>
              <div>
                <Modal show={show} onHide={handleClose} className="" id="couponlist" size="">
                  <Modal.Header className="pt-25 pb-25 pl-25 pr-25" closeButton>
                    <h2 className="ps-form__title font-size-24 mb-0">Choose Offer</h2>
                  </Modal.Header>
                  <Modal.Body className="ps-form--review modelbody padding-left-15 padding-right-15">
                    {coupons.length > 0 ? (
                      coupons.map((offer, index) => (
                        <div className={`coupon row m-0 ${selectedOffer === offer.code ? "selected" : ""}`}>
                          <div className="coupon-amount col-2 p-5">
                            <img src="/static/img/coupon/c3.png" alt="logo" />
                          </div>
                          <div className="coupon-details col-8 pr-0">
                            <p className="title text-capitalize">{offer.title}</p>
                            <p className="validity text-capitalize">
                              {offer.description ? offer.description : offer.code}
                            </p>
                            <span className="badge code">{offer.code}</span>
                          </div>
                          <div className="col-md-2 p-0">
                            <button
                              className="btn col-12 modal-listgroup-button"
                              onClick={() => setCounponFromList(offer.code, index)}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="no-offer">
                          <img src="/static/img/coupon/d_4.png" />
                          <p>No Offers Found.</p>
                        </div>
                      </>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" className="ps-btn ps-btn--warning" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <p id="couponerrormsg" className="color-red"></p>
              <p id="couponmsg" className="color-green"></p>
            </div>
          </div>
        )}
        {cartevent === "cart" && (
          <div className="card-box-shadow p-20 mt-30">
            <div className="ps-cart__actions">
              <div className="ps-cart--reward-container container p-0">
                <div className="row m-0">
                  <div className="reward-text col-8 pl-0">
                    <p className="title-font pl-1">Total Reward Points : {userReward && userReward.rewardpoints}</p>
                  </div>
                  <div
                    className={`reward-button col-4 p-0 ${
                      rewardPoints > 0 && SubTotal > (userRewardUseAndAmount && userRewardUseAndAmount.ordertotal)
                        ? ""
                        : "disabled btn lighter"
                    }`}
                  >
                    <button type="submit" onClick={handleUseRewardPoints}>
                      Use Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="ps-block__bottom mt-30">
          {IsFlag ? (
            <>
              {cartevent === "payment" && pinfound && (
                <React.Fragment>
                  {selectedPaymentMethod === "Razorpay" && (
                    <button
                      className={
                        disable
                          ? "ps-btn ps-btn--warning disabled disable-btn-color width-100-per mb-30 "
                          : "ps-btn ps-btn--warning mb-30 width-100-per"
                      }
                      onClick={razorPay}
                    >
                      Pay Now with Razorpay
                    </button>
                  )}
                  {selectedPaymentMethod === "Paypal" && (
                    <span>
                      {paypalbtn ? (
                        <PayPalScriptProvider
                          options={{
                            "client-id": config.paypalclientid,
                          }}
                        >
                          <PayPalButtons
                            style={{ layout: "horizontal" }}
                            createOrder={(data, actions) => {
                              saveOrder();
                              const totalAmount = paypalUSDAmount;
                              return actions.order.create({
                                intent: "CAPTURE",
                                purchase_units: [
                                  {
                                    reference_id: "deafult",
                                    amount: {
                                      value: totalAmount.toString(),
                                    },
                                  },
                                ],
                              });
                            }}
                            onApprove={(data, actions) => {
                              return actions.order.capture().then((details) => {
                                paymentobject["paymentmode"] = "Paypal";
                                if (details) {
                                  paymentobject["transactionid"] = details.purchase_units[0].payments.captures[0].id;
                                }
                                updateStatus();
                                removeItems("cart");
                                navigate("/ordersuccess", {
                                  state: { paymentObject: paymentobject, value: paymentobject.orderidvalue },
                                });
                              });
                            }}
                          />
                        </PayPalScriptProvider>
                      ) : (
                        <button
                          className={
                            disable
                              ? "ps-btn ps-btn--warning disabled disable-btn-color mb-30"
                              : "ps-btn ps-btn--warning mb-30 "
                          }
                          onClick={payPal}
                        >
                          Pay Now with PayPal
                        </button>
                      )}
                    </span>
                  )}
                  {selectedPaymentMethod === "PayU" && (
                    <button
                      className={
                        disable || !proceedFlag
                          ? "ps-btn ps-btn--orange disabled disable-btn-color"
                          : "ps-btn ps-btn--warning"
                      }
                      onClick={saveOrderPayUMoney}
                    >
                      Pay Now with PayuMoney
                    </button>
                  )}

                  {selectedPaymentMethod === "Stripe" && (
                    <button
                      className={
                        disable || !proceedFlag
                          ? "ps-btn ps-btn--orange disabled disable-btn-color"
                          : "ps-btn ps-btn--warning"
                      }
                      onClick={payWithStripe}
                    >
                      Pay Now with Stripe
                    </button>
                  )}

                  {selectedPaymentMethod === "CCAvenue" && (
                    <>
                      <form
                        id="nonseamless"
                        method="post"
                        onSubmit={(event) => payWithCCAvenue(event)}
                        name="redirect"
                        action={config.ccAvenueUri}
                      >
                        <>
                          <input type="hidden" id="encRequest" name="encRequest" value="" />
                          <input type="hidden" name="access_code" id="access_code" value="" />
                          <button
                            className={
                              disable || !proceedFlag
                                ? "ps-btn ps-btn--orange disabled disable-btn-color"
                                : "ps-btn ps-btn--warning"
                            }
                            type="submit"
                          >
                            Pay Now with CCAvenue
                          </button>
                        </>
                      </form>
                    </>
                  )}

                  {selectedPaymentMethod === "PhonePe" && (
                    <button
                      className={
                        disable || !proceedFlag
                          ? "ps-btn ps-btn--orange disabled disable-btn-color"
                          : "ps-btn ps-btn--warning"
                      }
                      onClick={payWithPhonePe}
                    >
                      Pay Now with Phone Pe
                    </button>
                  )}
                </React.Fragment>
              )}

              {cartevent === "cart" && (
                <Link
                  to={
                    validCoupon && localStorage.getItem("isRewardPointsApply") === "true"
                      ? `/checkout?coupon=${validCoupon}&rewardpoints=${rewardPoints}`
                      : validCoupon
                      ? `/checkout?coupon=${validCoupon}`
                      : localStorage.getItem("isRewardPointsApply") === "true"
                      ? `/checkout?rewardpoints=${rewardPoints}`
                      : "/checkout"
                  }
                  className={
                    disable || !proceedFlag
                      ? "warningbtn disabled disable-btn width-100-per max-width-unset"
                      : "warningbtn width-100-per max-width-unset"
                  }
                >
                  Proceed to checkout
                </Link>
              )}

              {cartevent === "summery" && pinfound && (
                <button
                  className={
                    disable || !proceedFlag
                      ? "warningbtn disabled disable-btn width-100-per max-width-unset"
                      : "warningbtn width-100-per max-width-unset"
                  }
                  onClick={redirectToSummery}
                >
                  Proceed to checkout
                </button>
              )}
            </>
          ) : (
            <>
              <a className="warningbtn  width-100-per max-width-unset" href="javascript:;" onClick={showError}>
                Proceed to checkout
              </a>
              <p id="shippingerrormsg" className={ErrorMsg ? "color-red pt-10" : "hide pt-10"}>
                {disable && ErrorMsg}
              </p>
            </>
          )}
          {payUData && (
            <div>
              <form name="ordernowform" id="ordernowform" action={payUData.paymentvalues.action} method="post">
                <input type="hidden" name="key" value={payUData.paymentvalues.key} />
                <input type="hidden" name="firstname" value={payUData.paymentvalues.firstname} />
                <input type="hidden" name="lastname" value={payUData.paymentvalues.lastname} />
                <input type="hidden" name="email" value={payUData.paymentvalues.email} />
                <input type="hidden" name="phone" value={payUData.paymentvalues.phone} />
                <input type="hidden" name="hash" value={payUData.paymentvalues.hash} />
                <input type="hidden" name="hashstring" value={payUData.paymentvalues.hashstring} />
                <input type="hidden" name="txnid" value={payUData.paymentvalues.txnid} />
                <input type="hidden" name="productinfo" value={payUData.paymentvalues.productinfo} />
                <input type="hidden" name="amount" value={payUData.paymentvalues.amount} />
                <input type="hidden" name="address1" value={payUData.paymentvalues.address1} />
                <input type="hidden" name="address2" value={payUData.paymentvalues.address2} />
                <input type="hidden" name="city" value={payUData.paymentvalues.city} />
                <input type="hidden" name="state" value={payUData.paymentvalues.country} />
                <input type="hidden" name="country" value={payUData.paymentvalues.country} />
                <input type="hidden" name="pg" value={payUData.paymentvalues.pg} />
                <input type="hidden" name="zipcode" value={payUData.paymentvalues.zipcode} />
                <input type="hidden" name="curl" value={payUData.paymentvalues.curl} />
                <input type="hidden" name="surl" value={payUData.paymentvalues.surl} />
                <input type="hidden" name="furl" value={payUData.paymentvalues.furl} />
                <input type="hidden" name="udf1" value={payUData.paymentvalues.udf1} />
                <input type="hidden" name="udf2" value={payUData.paymentvalues.udf2} />
                <input type="hidden" name="udf3" value={payUData.paymentvalues.udf3} />
                <input type="hidden" name="udf4" value={payUData.paymentvalues.udf4} />
                <input type="hidden" name="udf5" value={payUData.paymentvalues.udf5} />
                <input type="hidden" name="udf6" value={payUData.paymentvalues.udf6} />
                <input type="hidden" name="udf7" value={payUData.paymentvalues.udf7} />
                <input type="hidden" name="udf8" value={payUData.paymentvalues.udf8} />
                <input type="hidden" name="udf9" value={payUData.paymentvalues.udf9} />
                <input type="hidden" name="udf10" value={payUData.paymentvalues.udf10} />
                <input type="submit" value="submit" id="paynowbtn" />
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default connect((state) => state)(ModuleEcomerceCartSummary);
