import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { UrlProduct } from "../../../pages/product/LoadProduct";

const CustomPagination = ({ products }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [pagelist, setPageList] = useState([]);
  const [productslist, setproductsList] = useState(products);

  let page_no = searchParams.get("pageno");
  let lis = [];
  let pageno;
  let totalpages;
  let pagerange = 0;

  useEffect(() => {
    setproductsList(products);
    setParoductPages();
    window.scrollTo(0, 0);
  }, [page_no, products, productslist]);

  if (products && products.data && products.data.length > 0) {
    pageno = products.pageno;
    totalpages = products.totalpages;
    pagerange = products.pagerange;
  }

  /*
    To set paroduct pages 
  */
  const setParoductPages = () => {
    if (products && products.data && products.data.length > 0) {
      setPageList([]);
      var endpage = totalpages < pagerange + pageno ? totalpages : pagerange + pageno;
      var startpage = totalpages <= 6 ? 0 : pageno - 1;

      var pageDiff = totalpages - pageno;

      if (totalpages > 6 && totalpages - pageno < 6) {
        startpage = startpage - (5 - pageDiff);
      }

      for (var i = startpage; i < endpage; i++) {
        let activeclass = "";
        if (pageno === i + 1) {
          activeclass = "active";
        }
        lis.push(
          <li className={activeclass}>
            <Link to={UrlProduct(searchParams, "pagination") + "&pageno=" + `${i + 1}`}>{i + 1}</Link>
          </li>
        );
      }
      if (lis && lis.length > 0) {
        setPageList(lis);
      }
    }
  };

  return (
    <div className="ps-pagination">
      {totalpages > 1 && (
        <ul className="pagination">
          {products && products.data && products.data.length > 0 && (
            <>
              {Number(totalpages) >= Number(6) && (
                <li>
                  <Link to={UrlProduct(searchParams, "pagination") + "&pageno=1"}>
                    <i className="fa fa-angle-double-left"></i>
                  </Link>
                </li>
              )}
              <li>
                {pageno > 1 ? (
                  <Link to={UrlProduct(searchParams, "pagination") + "&pageno=" + `${pageno - 1}`}>
                    <i className="fa fa-angle-left"></i>
                  </Link>
                ) : (
                  <a>
                    <i className="fa fa-angle-left"></i>
                  </a>
                )}
              </li>
              {pagelist}
              <li>
                {totalpages > pageno ? (
                  <Link to={UrlProduct(searchParams, "pagination") + "&pageno=" + `${pageno + 1}`}>
                    <i className="fa fa-angle-right"></i>
                  </Link>
                ) : (
                  <a>
                    <i className="fa fa-angle-right"></i>
                  </a>
                )}
              </li>
              {Number(totalpages) >= Number(6) && (
                <li>
                  <Link to={UrlProduct(searchParams, "pagination") + "&pageno=" + totalpages}>
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </li>
              )}
            </>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomPagination;
