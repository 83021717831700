import React from "react";
import { Link } from "react-router-dom";

import config from "../../config/config.json";
import { currencyOrdePrice } from "../../hooks/currencyCalculation";
import { calculateTotalOrderQuantity } from "../../utilities/ecomerce-helpers";

const OrderListItem = ({ item }) => {
  let imagepath = config.apiUrl + config.image_path;

  return (
    <>
      <div className=" order-summary mb-30 order-box-rounded">
        <div className="col-12 order-basic-detail">
          <div className="ps-cart__content">
            <div className="ps-cart__items ">
              <div className="row">
                <div className="col-4 col-md-2 align-self-center orderno br-1">
                  <span className="">Order No</span>
                  <span>
                    <h2 className="ps-form__title">#{item.orderno}</h2>
                  </span>
                </div>
                <div className="col-6 col-md-5 ml-10">
                  <div>
                    Order Date <span className="title-font">{item.orderdate}</span>
                  </div>
                  <div className="pt-10">
                    IP <span className="title-font">{item.ip}</span>
                  </div>
                </div>
                <div className="col-12 col-md-5 align-self-center order-status">
                  <strong className="order-status-green-lable font-size-12">{item.status}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 p-15">
          <div className="ps-cart__content">
            <div className="ps-cart__items ">
              <div className="row">
                <div className="col-3 col-md-1 align-self-center order-product-img">
                  {item.orderproductlist && item.orderproductlist !== null && (
                    <Link to={`/order/${item.orderid}`}>
                      {item.orderproductlist[0].imagename !== null && item.orderproductlist[0].imagename !== "" ? (
                        <img
                          src={
                            item.orderproductlist[0].variantimagename
                              ? imagepath + item.orderproductlist[0].variantimagename
                              : imagepath + item.orderproductlist[0].imagename
                          }
                          alt={item.orderproductlist[0].imagename}
                          className={
                            item.orderproductlist.length > 1 ? "order-list-image img-more" : "order-list-image"
                          }
                        />
                      ) : (
                        <img
                          src="/static/img/noproduct-images.png"
                          alt="NoImage"
                          className={
                            item.orderproductlist.length > 1 ? "order-list-image img-more" : "order-list-image"
                          }
                        />
                      )}
                    </Link>
                  )}

                  {item.orderproductlist && item.orderproductlist !== null && item.orderproductlist.length > 1 && (
                    <div className="middle">
                      <div className="text">
                        <Link to={`/order/${item.orderid}`} className="color-black">
                          +{item.orderproductlist.length - 1}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-4 col-md-2 align-self-center">
                  <div className="order-pading">
                    <span className="">Quantity </span>
                    <span className="title-font">
                      {item.orderproductlist && item.orderproductlist !== null ? (
                        <span className="title-font">{calculateTotalOrderQuantity(item.orderproductlist)}</span>
                      ) : (
                        "0"
                      )}
                    </span>
                  </div>
                  <div className="order-pading">
                    <span className="">Total </span>
                    <span className="title-font">{currencyOrdePrice(item.total, item.currencyForm)}</span>
                  </div>
                </div>
                <div className="col-5 col-md-3 text-center align-self-center">
                  <span>Payment Status </span>
                  <div className="order-status-green title-font">{item.paymentstatus}</div>
                </div>
                <div className="col-12 col-md-3 transaction">
                  {item.transactionid && (
                    <>
                      <span>Payment ref </span>
                      <p className="title-font color-blue word-break-all">{item.transactionid}</p>
                    </>
                  )}
                </div>
                <div className="col-12 col-md-3 order-detail-btn">
                  <Link to={`/order/${item.orderid}`} className={"order-btn"} title="View Detail">
                    View Detail
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderListItem;
